import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import { ToggleSwitch } from '@C/FormElements';
import { AccessControl, ChartList } from '@C/Utils';
import { authHeader } from '@H';
import { authService } from '@S';

import ProductList from './ProductList';
import { TankList } from './TankList';

class TankSetup extends Component {
  constructor() {
    super();
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
    this.myRef = React.createRef();
  }

  state = {
    tankDetails: [
      {
        index: Math.random(),
        name: '',
        product_uuid: '',
        product_name: '',
        capacity: '',
        price: 1,
        dip_reading: 0,
        number_of_guns: '',
        calibration_chart_name: '',
        calibration_chart_id: null,
        gun_list: [],
        is_bowser: false,
      },
    ],
    idx: 0,
    name: '',
    capacity: '',
    product_name: 'Select Fuel',
    product_uuid: '',
    number_of_guns: '',
    calibration_chart_name: '',
    calibration_chart_id: null,
    is_bowser: false,
    update: false,
  };

  handleChange = (e) => {
    if (['is_bowser'].includes(e.target.name)) {
      const tankDetails = [...this.state.tankDetails];
      tankDetails[e.target.dataset.id][e.target.name] = e.target.checked;
      this.setState({ [e.target.name]: e.target.checked });
    } else {
      const tankDetails = [...this.state.tankDetails];
      tankDetails[e.target.dataset.id][e.target.name] = e.target.value;
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  handleProductChange = (e, f) => {
    const tankDetails = [...this.state.tankDetails];
    tankDetails[f]['product_uuid'] = e.value;
    tankDetails[f]['product_name'] = e.label;
    this.setState({ product_uuid: e.value, product_name: e.label });
  };

  handleChartChange = (e, f) => {
    console.log(e, f);
    const tankDetails = [...this.state.tankDetails];
    tankDetails[f]['calibration_chart_name'] = e?.label || '';
    tankDetails[f]['calibration_chart_id'] = e?.value || null;
    this.setState({
      calibration_chart_name: e.label,
      calibration_chart_id: e?.value || null,
    });
  };

  handleGunList = (e) => {
    const g = [];

    const tankDetails = [...this.state.tankDetails];
    for (let i = 0; i < e.target.value; i++) {
      g[i] = {
        name: `${tankDetails[e.target.dataset.id]['name']} - Gun ${i + 1}`,
      };
    }
    tankDetails[e.target.dataset.id]['gun_list'] = g;
    tankDetails[e.target.dataset.id]['number_of_guns'] = e.target.value;
    this.setState({ number_of_guns: e.target.value });
  };

  addItem = (e) => {
    e.preventDefault();
    window.scrollTo({
      behavior: 'smooth',
      top: this.myRef.current.offsetTop - 150,
    });
    if (this.validator.allValid()) {
      this.validator.hideMessages();
      this.setState((prevState) => ({
        tankDetails: [
          ...prevState.tankDetails,
          {
            index: Math.random(),
            name: '',
            product_uuid: '',
            product_name: '',
            capacity: '',
            price: 1,
            dip_reading: 0,
            calibration_chart_name: '',
            calibration_chart_id: null,
            number_of_guns: '',
            gun_list: [],
            is_bowser: false,
          },
        ],
        idx: this.state.idx + 1,
        name: '',
        capacity: '',
        number_of_guns: '',
        product_name: 'Select Fuel',
        product_uuid: '',
        calibration_chart_id: null,
        is_bowser: false,
      }));
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
  };

  deleteItem = (index) => {
    this.setState({
      tankDetails: this.state.tankDetails.filter(
        (s, sindex) => index !== sindex,
      ),
      idx: this.state.idx - 1,
    });
  };

  submitForm = () => {
    const tankData = this.state.tankDetails;
    const formData = {
      tanks: tankData.filter((item) => item.name !== ''),
      associate_account: authService.currentAccountID,
    };

    const headers = authHeader(1);
    axios
      .post(`${process.env.API_URL}/bunk/tank-mapping/`, formData, { headers })
      .then(() => {
        toast.success('Tanks added successfully');
        window.scrollTo({
          behavior: 'smooth',
          top: this.myRef.current.offsetTop - 150,
        });
        this.setState({
          tankDetails: [
            {
              index: Math.random(),
              name: '',
              product_uuid: '',
              product_name: '',
              capacity: '',
              calibration_chart_name: '',
              calibration_chart_id: null,
              price: 0,
              dip_reading: 0,
              number_of_guns: '',
              gun_list: [],
              is_bowser: false,
            },
          ],
          idx: 0,
          name: '',
          capacity: '',
          number_of_guns: '',
          product_name: 'Select Fuel',
          product_uuid: '',
          calibration_chart_name: 'Select chart name',
          calibration_chart_id: null,
          is_bowser: false,
          updated: !this.state.updated,
        });
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  render() {
    const { tankDetails } = this.state;
    return (
      <>
        <AccessControl allowedRoles={['Dealer']}>
          <section className="widget form-card" ref={this.myRef}>
            <header>
              <h3>Add new tank</h3>
            </header>
            <main>
              <form>
                <div className="input-wrapper with-label">
                  {this.validator.message(
                    'name',
                    this.state.name,
                    'required|alpha_num_dash_space',
                  )}
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    data-id={this.state.idx}
                    id="name"
                    name="name"
                    onChange={this.handleChange}
                    value={this.state.name}
                  />
                </div>
                <div className="input-wrapper with-label">
                  {this.validator.message(
                    'capacity',
                    this.state.capacity,
                    'required|integer|min:1,num',
                    {
                      messages: {
                        integer: 'The capacity must be a number.',
                        min: 'The minimum capacity should be atleast 1',
                      },
                    },
                  )}
                  <label htmlFor="capacity">Capacity</label>
                  <input
                    type="text"
                    data-id={this.state.idx}
                    id="capacity"
                    name="capacity"
                    onChange={this.handleChange}
                    value={this.state.capacity}
                  />
                </div>
                <div className="input-wrapper">
                  {this.validator.message(
                    'product_uuid',
                    this.state.product_uuid,
                    'required',
                    { messages: { required: 'Please select a fuel' } },
                  )}
                  <ProductList
                    did={this.state.idx}
                    id="product_uuid"
                    handleProductChange={this.handleProductChange}
                    value={this.state.product_uuid}
                  />
                </div>
                <div className="input-wrapper with-label">
                  {this.validator.message(
                    'number_of_guns',
                    this.state.number_of_guns,
                    'required|numeric|min:1,num|max:20,num',
                    {
                      messages: {
                        required: 'Please enter the number of guns',
                        min: 'The minimum number of gun should be atleast 1',
                      },
                    },
                  )}
                  <label htmlFor="guns">Number of guns</label>
                  <input
                    type="text"
                    data-id={this.state.idx}
                    id="guns"
                    name="guns"
                    onChange={this.handleGunList}
                    value={this.state.number_of_guns}
                  />
                </div>
                <div className="input-wrapper with-label">
                  <label htmlFor="calibration_chart_name">
                    {' '}
                    Link tank with appropriate dip calibration chart{' '}
                  </label>
                  <ChartList
                    did={this.state.idx}
                    id="calibration_chart_id"
                    handleChartChange={this.handleChartChange}
                    value={this.state.calibration_chart_id}
                  />
                </div>
                <div className="input-wrapper toggle-element">
                  <span className="label"> Is bowser: </span>
                  <ToggleSwitch
                    did={this.state.idx}
                    id="is_bowser"
                    name="is_bowser"
                    checked={this.state.is_bowser}
                    onChange={this.handleChange}
                  />
                </div>

                <div className="cta-wrapper">
                  <button className="submit" onClick={this.addItem}>
                    Add
                  </button>
                </div>
              </form>
            </main>
          </section>
          <section className="widget list-card list-card__minimal ">
            <header>
              <h3>Tank List</h3>
            </header>
            <main>
              <ul>
                {tankDetails.map((item, i) => {
                  if (i < tankDetails.length - 1) {
                    return (
                      <li key={i}>
                        <div className="card-title">
                          <label>Name:</label>
                          <h4>{item.name}</h4>
                        </div>
                        <div className="card-info">
                          <span>
                            <label>Fuel:</label>
                            {item.product_name}
                          </span>
                          <span>
                            <label>Capacity:</label>
                            {item.capacity}
                          </span>
                          <span>
                            <label>Chart name:</label>
                            {item.calibration_chart_name}
                          </span>
                          <span>
                            {' '}
                            <label>Guns:</label> {item.number_of_guns}
                          </span>
                          <span>
                            <label>Bowser:</label>
                            {item.is_bowser.toString()}
                          </span>
                        </div>
                        <div className="card-actions">
                          <button
                            className="actions __delete "
                            onClick={() => this.deleteItem(i)}
                          >
                            Delete
                          </button>
                        </div>
                      </li>
                    );
                  }
                })}
              </ul>
              {tankDetails.length > 1 && (
                <div className="cta-wrapper">
                  <button
                    className="submit"
                    type="submit"
                    onClick={this.submitForm}
                  >
                    Submit
                  </button>
                </div>
              )}
            </main>
          </section>
        </AccessControl>
        <TankList key={this.state.updated} />
      </>
    );
  }
}

export { TankSetup };
