import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import { LubesCategoryList } from '@C/Lubes/_utils/';
import services from '@C/Lubes/_utils/data';
import { TableGrid } from '@C/TableGrid';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { authHeader } from '@H';

const col = [
  {
    name: 'Name',
    key: 'name',
  },
  {
    name: 'Category',
    key: 'category_name',
  },
  {
    name: 'HSN Code',
    key: 'hsn_code',
  },

  {
    name: 'Price excl GST',
    key: 'selling_price',
    type: 'currency',
  },

  {
    name: 'Stock',
    key: 'number_of_units',
  },
  {
    name: 'Stock on date',
    key: 'stock_on_date',
  },
];

const enabledActions = [
  {
    action: 'Edit',
    roles: ['Site Admin', 'Dealer'],
  },
  {
    action: 'Delete',
    roles: ['Site Admin', 'Dealer'],
  },
];

class LubeInventory extends Component {
  constructor() {
    super();
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
    this.myRef = React.createRef();
  }

  state = {
    lubes_list: [
      {
        name: '',
        image_url: '',
        category_id: '',
        hsn_code: '',
        selling_price: '',
        number_of_units: 0,
        time_stamp: dayjs().format('YYYY-MM-DD'),
      },
    ],
    updated: false,
    editMode: false,
    isSubmitting: false,
  };

  addRow = () => {
    this.setState((prevState) => ({
      lubes_list: [
        ...prevState.lubes_list,
        {
          name: '',
          image_url: '',
          category_id: '',
          hsn_code: '',
          selling_price: '',
          number_of_units: 0,
          time_stamp: dayjs().format('YYYY-MM-DD'),
        },
      ],
    }));
  };

  deleteRow = (i) => {
    this.setState({
      lubes_list: this.state.lubes_list.filter((s, sindex) => i !== sindex),
    });
  };

  onInputChange = (e, i) => {
    const list = [...this.state.lubes_list];
    if (e.target.type == 'text') {
      list[i][e.target.name] = e.target.value;
    } else {
      list[i][e.target.name] = parseFloat(e.target.value);
    }

    this.setState({ lubes_list: list });
  };

  handleCategoryChange = (e, i) => {
    const list = [...this.state.lubes_list];
    list[i].category_id = e.value;
    this.setState({ lubes_list: list });
  };

  handleDate = (e, i) => {
    const tmp = [...this.state.lubes_list];
    tmp[i].time_stamp = e.target.value;
    this.setState({ lubes_list: tmp });
  };

  editMode = (item) => {
    this.setState({ editMode: false });
    window.scrollTo({
      behavior: 'smooth',
      top: this.myRef.current.offsetTop - 100,
    });
    const tmp = item;
    const lubesList = [{}];
    lubesList[0].uuid = tmp.uuid;
    lubesList[0].name = tmp.name;
    lubesList[0].category_id = tmp.category_uuid;
    lubesList[0].hsn_code = tmp.hsn_code;
    lubesList[0].selling_price = tmp.selling_price;
    lubesList[0].number_of_units = tmp.number_of_units;
    (lubesList[0].time_stamp = dayjs(tmp.time_stamp).format('YYYY-MM-DD')),
      this.setState({
        lubes_list: lubesList,
        editMode: true,
      });
  };

  cancelEdit = () => {
    this.setState({
      lubes_list: [
        {
          name: '',
          image_url: '',
          category_id: '',
          hsn_code: '',
          selling_price: '',
          number_of_units: 0,
          time_stamp: dayjs().format('YYYY-MM-DD'),
        },
      ],
      updated: false,
      editMode: false,
      isSubmitting: false,
    });
  };

  submitForm = () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.setState({ isSubmitting: true });
    const formData = this.state;
    const headers = authHeader(1);

    if (this.state.editMode) {
      axios
        .put(`${process.env.API_URL}/lubes/bunk/lube/`, formData, { headers })
        .then(() => {
          toast.success('Lubes Inventory updated successfully', {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          window.scrollTo({
            behavior: 'smooth',
            top: this.myRef.current.offsetTop - 150,
          });
          this.setState({
            lubes_list: [
              {
                name: '',
                image_url: '',
                category_id: '',
                hsn_code: '',
                selling_price: '',
                number_of_units: 0,
                time_stamp: dayjs().format('YYYY-MM-DD'),
              },
            ],
            updated: !this.state.updated,
            isSubmitting: false,
          });
          this.validator.hideMessages();
        })
        .catch((err) => {
          toast.error(err.message, {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.setState({ isSubmitting: false });
        });
    } else {
      axios
        .post(`${process.env.API_URL}/lubes/bunk/lube/`, formData, {
          headers,
        })
        .then(() => {
          toast.success('Lubes added successfully', {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          window.scrollTo({
            behavior: 'smooth',
            top: this.myRef.current.offsetTop - 150,
          });
          this.setState({
            lubes_list: [
              {
                name: '',
                image_url: '',
                category_id: '',
                hsn_code: '',
                selling_price: '',
                number_of_units: 0,
                time_stamp: dayjs().format('YYYY-MM-DD'),
              },
            ],
            updated: !this.state.updated,
            isSubmitting: false,
          });
          this.validator.hideMessages();
          this.forceUpdate();
        })
        .catch((err) => {
          toast.error(err.data.message, {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.setState({ isSubmitting: false });
        });
    }
  };

  render() {
    const lubesList = this.state.lubes_list;
    const editMode = this.state.editMode;
    return (
      <>
        <section className="widget full-width form-table" ref={this.myRef}>
          <header className="form-header">
            <h3>{editMode ? 'Edit' : 'Add'} Lube / Products</h3>

            <div className="actions">
              <button
                className={`add-row ${editMode ? 'hidden' : ''}`}
                onClick={() => this.addRow()}
              >
                Add row
              </button>
            </div>
          </header>
          <main>
            <div className="form-content">
              <table>
                <thead>
                  <tr>
                    <th> Date </th>
                    <th>Product Name</th>
                    <th>Product Category</th>
                    <th>HSN Code</th>
                    <th>Price excl GST</th>
                    {/* <th>Stock</th> */}
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {lubesList.map((item, i) => (
                    <tr key={i}>
                      <td>
                        <div className="input-wrapper">
                          <input
                            type="date"
                            name="time_stamp"
                            value={item.time_stamp}
                            onChange={() => this.handleDate(event, i)}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper">
                          {this.validator.message(
                            'name',
                            lubesList[i].name,
                            'required',
                          )}
                          <input
                            name="name"
                            type="text"
                            value={lubesList[i].name}
                            onChange={() => this.onInputChange(event, i)}
                            placeholder="Name"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper">
                          {this.validator.message(
                            'category_id',
                            lubesList[i].category_id,
                            'required',
                          )}
                          <LubesCategoryList
                            did={i}
                            handleDropdownChange={this.handleCategoryChange}
                            value={lubesList[i].category_id}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper">
                          <input
                            type="text"
                            value={lubesList[i].hsn_code}
                            placeholder="HSN "
                            name="hsn_code"
                            onChange={() => this.onInputChange(event, i)}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper">
                          {this.validator.message(
                            'selling_price',
                            lubesList[i].selling_price,
                            'required|numeric|num',
                          )}
                          <input
                            type="text"
                            value={item.selling_price}
                            placeholder="Price excl GST"
                            name="selling_price"
                            onChange={() => this.onInputChange(event, i)}
                          />
                        </div>
                      </td>
                      {/* <td>
                        <div className="input-wrapper">
                          <input
                            type="text"
                            value={item.number_of_units}
                            placeholder="Stock"
                            name="number_of_units"
                            onChange={() => this.onInputChange(event, i)}
                            disabled={editMode}
                          />
                        </div>
                      </td> */}
                      <td>
                        <div className="input-wrapper _actions">
                          {i > 0 ? (
                            <button
                              className="delete-row icon-only"
                              onClick={() => this.deleteRow(i)}
                            >
                              Delete row
                            </button>
                          ) : (
                            ''
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="form-actions">
              <button
                className="submit"
                type="submit"
                disabled={this.state.isSubmitting}
                onClick={this.submitForm}
              >
                Submit
              </button>
              {editMode && (
                <button className="red-btn" onClick={this.cancelEdit}>
                  Cancel
                </button>
              )}
            </div>
          </main>
        </section>
        <LubesList key={this.state.updated} onEdit={this.editMode} />
      </>
    );
  }
}

class LubesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lubesList: [],
      stockList: [],
      limit: 20,
      offset: 1,
      count: 0,
      time_stamp: dayjs().format('YYYY-MM-DD'),
      loading: true,
    };
    this.limitInput = React.createRef();
  }

  componentDidMount() {
    this.getLubesList();
  }

  getLubesList = () => {
    services
      .getAllLubes(this.state)
      .then((response) => {
        this.setState({
          lubesList: response.data.data.results,
          stockList: response.data.data.total_stock_value,
          count: response.data.data.count,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handlePagination = (e) => {
    this.setState({ offset: e, loading: true }, function () {
      this.getLubesList();
    });
  };

  handleDateChange = (e) => {
    this.setState(
      { [e.target.name]: e.target.value, loading: true },
      function () {
        this.getLubesList();
      },
    );
  };

  handleQuickSearch = (e) => {
    e.preventDefault();
    if (
      this.limitInput.current.value === '' ||
      this.limitInput.current.value === '0'
    ) {
      return;
    }
    this.setState(
      { limit: this.limitInput.current.value, offset: 1 },
      function () {
        this.getLubesList();
      },
    );
  };

  resetFilter = () => {
    this.setState(
      {
        time_stamp: dayjs().format('YYYY-MM-DD'),
        loading: true,
      },
      function () {
        this.getLubesList();
      },
    );
  };

  editItem = (selectedId) => {
    this.setState({ selectedId: selectedId, showModal: true });
  };
  closeModal = () => {
    this.setState({ showModal: false });
  };

  confirmDelete = (item) => {
    confirmAlert({
      title: 'Confirm Lube Inventory deletion',
      message: 'Are you sure',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteItem(item),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  deleteItem = (item) => {
    const headers = authHeader(1);
    axios
      .delete(`${process.env.API_URL}/lubes/bunk/lube/?lube_id=${item.uuid}`, {
        headers,
      })
      .then(() => {
        toast.success('Lube Inventory deleted successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        this.getLubesList();
      });
  };

  render() {
    const { lubesList, stockList } = this.state;
    return (
      <section className="widget full-width list-table">
        <header>
          <h3>Lube / Products List</h3>
        </header>
        <main className="list-content">
          <div className="table-filters">
            <div className="filter-wrapper">
              <div className="filter-group with-icon">
                <label>
                  Results to display- {this.state.limit || 20} (Total:{' '}
                  {this.state.count})
                </label>
                <form onSubmit={this.handleQuickSearch}>
                  <input type="text" name="limit" ref={this.limitInput} />
                  <button
                    type="submit"
                    className="search-icon"
                    onClick={this.handleQuickSearch}
                  />
                </form>
              </div>
              <div className="filter-group">
                <label>Date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.time_stamp}
                  name="time_stamp"
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="filter-group reset-filter">
                <button onClick={this.resetFilter}>Reset filters</button>
              </div>
            </div>
          </div>
          <div className="table-wrapper --full">
            {(() => {
              if (this.state.loading) {
                return <DropLoaderLarge />;
              } else {
                return (
                  <>
                    <TableGrid
                      col={col}
                      row={lubesList}
                      offset={this.state.offset}
                      count={this.state.count}
                      limit={this.state.limit}
                      handlePagination={this.handlePagination}
                      onEdit={this.props.onEdit}
                      onDelete={this.confirmDelete}
                      enabledActions={enabledActions}
                      exportOptions={{
                        fileName: `Lube/ Products Inventory - ${this.state.time_stamp}.pdf`,
                        reportName: 'Lube/ Products Inventory',
                        reportDate: `Date - ${dayjs(this.state.time_stamp).format('D MMM YY')}`,
                        reportTotal: `Total stock value ${stockList.stock_value.toFixed(2)}`,
                        reportQuantity: '',
                        reportSales: '',
                        reportTesting: '',
                        reportBowser: '',
                        reportCustomerName: '',
                        reportGst: '',
                        reportOpeningBalance: '',
                        reportClosingBalance: '',
                        reportSaleTotal: '',
                        reportTCSTotal: '',
                      }}
                    />
                  </>
                );
              }
            })()}
            <div className="mini-summary">
              <ul>
                <li>
                  <span> Total stock value : </span>
                  <span>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(stockList.stock_value || 0)}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </main>
      </section>
    );
  }
}

export { LubeInventory };
