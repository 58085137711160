import React, { Component } from 'react';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import services from '@C/CashiersOnDuty/_utils/data';
import { formatCurrency } from '@C/TableGrid';
import { authHeader } from '@H';

class SwipeSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cashier_id: this.props.match.params.staff,
      cashier_duty_id: this.props.match.params.duty,
      wallets: [],
      wallet: [],
      activeblock: false,
      activeUUId: '',
      expanded: false,
      isSubmitting: false,
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
  }

  componentDidMount() {
    this.getCashDetails();
  }

  getCashDetails = () => {
    services
      .getTotalCollection(
        this.props.match.params.staff,
        this.props.match.params.duty,
      )
      .then((response) => {
        this.setState({ wallets: response.data.data.cards });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getSwipeSummaryList = () => {
    services
      .getSwipeSummaryList(
        this.props.match.params.staff,
        this.props.match.params.duty,
        this.state.activeUUId,
      )
      .then((response) => {
        this.setState({ wallet: response.data.data.results });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleChange = (e, i) => {
    const list = [...this.state.wallets];
    list[i][e.target.name] = e.target.value;
    this.setState({ wallets: list });
  };

  handleopen = (i, id) => {
    const isExpanded = i === this.state.activeblock && this.state.expanded;
    this.setState(
      { expanded: !isExpanded, activeblock: i, activeUUId: id },
      function () {
        this.getSwipeSummaryList();
      },
    );
  };

  submitForm = (e, i) => {
    e.preventDefault();
    this.setState({ isSubmitting: true });
    const list = [...this.state.wallets];
    if (
      list[i].amount <= 0 ||
      list[i].amount == '' ||
      list[i].amount == undefined ||
      isNaN(list[i].amount)
    ) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    const formData = {
      payment_list: [
        {
          amount: list[i].amount,
          mode: 'pos',
          bank_id: list[i].uuid,
          payment_method: 'IMPS',
          transaction_source: 'SWIPE',
          product_type: 'Fuel',
          product_uuid: null,
          bank_reference_id: list[i].bank_reference_id,
        },
      ],
      staff_id: this.props.match.params.staff,
      cashier_duty_id: this.props.match.params.duty,
    };
    const headers = authHeader(1);

    axios
      .post(`${process.env.API_URL}/payment/`, formData, { headers })
      .then(() => {
        toast.success('Slips added successfully');
        this.props.onDataChange();
        list[i].amount = '';
        list[i].bank_reference_id = '';
        this.setState({ wallets: list, isSubmitting: false });
        this.getCashDetails();
        this.getSwipeSummaryList();
        this.props.onDataChange();
        this.validator.hideMessages();
        this.forceUpdate();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        this.setState({ isSubmitting: false });
      });
  };

  confirmDelete = (id, duty, source) => {
    confirmAlert({
      title: 'Confirm transaction deletion',
      message: 'Are you sure',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteItem(id, duty, source),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  deleteItem = (id, duty, source) => {
    const headers = authHeader(1);
    axios
      .delete(
        `${process.env.API_URL}/payment/?transaction_id=${id}&cashier_duty_id=${duty}&source=${source}`,
        {
          headers,
        },
      )
      .then(() => {
        toast.success('Transaction deleted successfully');
        this.getCashDetails();
        this.getSwipeSummaryList();
        this.props.onDataChange();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  render() {
    const wallets = this.state.wallets;
    const wallet = this.state.wallet;
    return (
      <>
        <section className="widget accordion-card">
          <header>
            <h3>POS Summary</h3>
          </header>
          <main>
            <div className="accordion-container">
              {wallets.map((item, i) => (
                <div
                  className={
                    this.state.activeblock === i && this.state.expanded
                      ? 'accordion-item active'
                      : 'accordion-item'
                  }
                  key={i}
                >
                  <header
                    className="accordion-header"
                    onClick={() => this.handleopen(i, item.uuid)}
                  >
                    <h4>{item.name}</h4>
                    <span>{formatCurrency(item.total_balance)}</span>
                  </header>
                  <div className="accordion-content">
                    {item.is_active ? (
                      <form
                        className="inline"
                        onSubmit={() => this.submitForm(event, i)}
                      >
                        <div className="input-wrapper with-label">
                          {this.validator.message(
                            'amount',
                            item.amount,
                            'required|numeric',
                          )}
                          <label>Amount</label>
                          <input
                            name="amount"
                            type="text"
                            value={item.amount}
                            onChange={() => this.handleChange(event, i)}
                          />
                        </div>
                        <div className="input-wrapper with-label">
                          <label>Batch ID</label>
                          <input
                            name="bank_reference_id"
                            type="text"
                            value={item.bank_reference_id}
                            onChange={() => this.handleChange(event, i)}
                          />
                        </div>
                        <div className="cta-wrapper">
                          <button
                            type="submit"
                            className="submit"
                            disabled={this.state.isSubmitting}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    ) : null}
                    <div className="table-wrapper">
                      <table>
                        <thead>
                          <tr>
                            <th>
                              <span>#</span>
                            </th>
                            <th>
                              <span>Description</span>
                            </th>
                            <th>
                              <span>Amount</span>
                            </th>
                            <th>
                              <span>Batch ID</span>
                            </th>
                            <th>
                              <span>Actions</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {wallet.map((info, index) => {
                            return (
                              <tr key={index} className="table-body">
                                <td> {index + 1} </td>
                                <td>
                                  {info.payment_type == 'Other'
                                    ? 'Other Income'
                                    : info.payment_type}
                                </td>
                                <td> {formatCurrency(info.after_tax)}</td>
                                <td> {info.bank_reference_id}</td>
                                <td className="item-actions">
                                  <button
                                    className="actions __delete"
                                    onClick={() =>
                                      this.confirmDelete(
                                        info.uuid,
                                        info.cashier_duty.cashier_duty_id,
                                        info.source,
                                      )
                                    }
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </main>
        </section>
      </>
    );
  }
}

export default SwipeSummary;
