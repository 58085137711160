import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import services from '@C/Banking/_utils/data';
import { TableGrid } from '@C/TableGrid';
import { WalletList } from '@C/Banking/_utils/WalletList';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { authHeader } from '@H';

import './styles.scoped.scss';

const WalletLedger = () => {
  const [posList, setPosList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [txid, setTxid] = useState([]);
  const [bankOptions, setBankOptions] = useState([]);
  const [walletData, setWalletData] = useState({
    offset: 1,
    count: 0,
    limit: 20,
    bank_id: '',
    start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    end_date: dayjs().format('YYYY-MM-DD'),
    time_stamp: dayjs().format('YYYY-MM-DD'),
    uuid: '',
    refreshList: false,
  });

  const limitInput = useRef();
  const posListRef = useRef();

  const col = [
    {
      name: 'Select',
      key: 'all',
      formatter(props) {
        return (
          <>
            {' '}
            <input value={props.uuid} type="checkbox" onChange={handleChange} />
          </>
        );
      },
    },
    {
      name: 'Date',
      key: 'time_stamp',
      sortable: true,
      type: 'date',
    },
    {
      name: 'Narration',
      key: 'narration',
    },
    {
      name: 'Amount credited',
      key: 'amount',
      type: 'currency',
    },
    {
      name: 'Settlement date',
      key: 'settlement_date',
      type: 'date',
    },
  ];

  useEffect(() => {
    getMappedWallets();
  }, []);

  useEffect(() => {
    if (walletData.bank_id) {
      getWalletLedger();
    }
  }, [walletData.refreshList]);


  const getWalletLedger = () => {
    services
      .getWalletLedger(walletData)
      .then((response) => {
        const tmp = response.data.data.results;
        const posList = tmp;
        setWalletData({ ...walletData, count: response.data.data.count });
        setPosList(tmp);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getMappedWallets = () => {
    services
      .getMappedWallets()
      .then((response) => {
        const tmp = response.data.data;
        const CategoryOptions = tmp.map((item) => ({
          value: item.bunk_wallet_uuid,
          label: `${item.wallet.bank_name}(${item.settlement_bank.settlement_bank_name})`,
        }));
        setBankOptions(CategoryOptions);
        if (CategoryOptions.length > 0) {
          const defaultBankId = CategoryOptions[0].value;
          setWalletData((prevData) => ({
            ...prevData,
            bank_id: defaultBankId,
            refreshList: !prevData.refreshList,
          }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePagination = (e) => {
    setWalletData(
      { ...walletData, offset: e, refreshList: !walletData.refreshList },
      function () {
        getWalletLedger();
      },
    );
  };

  const handleChange = (e) => {
    if (e.target.checked) {
      setTxid((prevState) => [...prevState, e.target.value]);
    } else {
      const arr = txid.filter((item) => item !== e.target.value);
      setTxid(arr);
    }
  };

  const handleDate = (event) => {
    const value = event.target.value;
    setWalletData(() => ({
      ...walletData,
      time_stamp: value,
    }));
  };

  const handleQuickSearch = (e) => {
    e.preventDefault();
    if (limitInput.current.value === '' || limitInput.current.value === '0') {
      return;
    }
    setWalletData({
      ...walletData,
      limit: limitInput.current.value,
      refreshList: !walletData.refreshList,
    });
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;

    setWalletData((prevWalletData) => {
      if (
        name === 'start_date' &&
        dayjs(value).isAfter(prevWalletData.end_date)
      ) {
        return {
          ...prevWalletData,
          [name]: value,
          end_date: value,
        };
      } else if (
        name === 'end_date' &&
        dayjs(value).isBefore(prevWalletData.start_date)
      ) {
        return {
          ...prevWalletData,
          [name]: value,
          start_date: value,
        };
      } else {
        return {
          ...prevWalletData,
          [name]: value,
        };
      }
    });
  };

  const handleDropChange = (e) => {
    setWalletData((prevData) => ({
      ...prevData,
      bank_id: e?.value,
    }));
  };

  const resetFilter = () => {
    posListRef.current.handleReset();
    setWalletData({
      offset: 1,
      count: 0,
      limit: 20,
      bank_id: bankOptions[0]?.value || '',
      start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      end_date: dayjs().format('YYYY-MM-DD'),
      time_stamp: dayjs().format('YYYY-MM-DD'),
      refreshList: !walletData.refreshList,
    });
    setTxid([]);
  };

  const submitForm = () => {
    if (txid.length === 0) {
      toast.error('Please select transactions to mark as settled.');
      return;
    }
    const headers = authHeader(1);
    axios({
      method: 'get',
      url: `${process.env.API_URL}/payment/settlement/?date=${walletData.time_stamp}&tx_id=${txid.join(',')}`,
      headers: headers,
    })
      .then((response) => {
        toast.success('Transactions marked as settled successfully');
        setWalletData({ ...walletData, refreshList: !walletData.refreshList });
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const applyForm = (e) => {
    e.preventDefault();
    setLoading(true);
    setWalletData({ ...walletData, refreshList: !walletData.refreshList });
  };

  return (
    <>
      <section className="widget full-width list-table inverted">
        <header>
          <h3> Wallet Settlement</h3>
        </header>
        <main>
          <div className="table-filters">
            <div className="filter-wrapper">
              <div className="filter-group">
                <label>From date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={walletData.start_date}
                  name="start_date"
                  onChange={handleDateChange}
                />
              </div>
              <div className="filter-group">
                <label>To date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={walletData.end_date}
                  onChange={handleDateChange}
                  name="end_date"
                />
              </div>
              <div className="filter-group">
                <label>Select Wallet</label>
                <WalletList
                  value={walletData.bank_id}
                  handleBankChange={handleDropChange}
                  ref={posListRef}
                />
              </div>
              <div className="filter-group with-icon">
                <label>
                  Results to display- {walletData.limit || 20} (Total:{' '}
                  {walletData.count})
                </label>
                <form onSubmit={handleQuickSearch}>
                  <input type="text" name="limit" ref={limitInput} />
                  <button
                    type="submit"
                    className="search-icon"
                    onClick={handleQuickSearch}
                  ></button>
                </form>
              </div>
              <div className="filter-group reset-filter">
                <button onClick={resetFilter}>Reset filters</button>
              </div>
              <div className="filter-group">
                <button className="submit" type="submit" onClick={applyForm}>
                  Apply filter
                </button>
              </div>
            </div>
          </div>
          <div className="form-header">
            <div className="input-wrapper with-label">
              <label> Select Settlement Date </label>
              <input
                className="cstm-date-input"
                type="date"
                value={walletData.time_stamp}
                name="time_stamp"
                onChange={handleDate}
              />
            </div>

            <div className="form-actions">
              <button className="submit" type="submit" onClick={submitForm}>
                Mark as Settled
              </button>
            </div>
          </div>
          <div className="table-wrapper">
            {(() => {
              if (loading) {
                return <DropLoaderLarge />;
              } else {
                return (
                  <>
                    <TableGrid
                      col={col}
                      row={posList}
                      offset={walletData.offset}
                      count={walletData.count}
                      limit={walletData.limit}
                      handlePagination={handlePagination}
                      hideActions={true}
                      enableExport={false}
                    />
                  </>
                );
              }
            })()}
          </div>
        </main>
      </section>
    </>
  );
};

export { WalletLedger };
