import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { ToggleSwitch } from '@C/FormElements';
import services from '@C/StaffManagement/_utils/data';
import { AttendanceFields, StaffList } from '@C/Utils';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import { authHeader } from '@H';

import '@C/StaffManagement/RouteComponents/AdvanceReturn/styles.scoped.scss';

const Attendance = () => {
  const [staffDetails, setStaffDetails] = React.useState({});
  const [selectedStaff, setSelectedStaff] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedDate, setSelectedDate] = React.useState({
    start_date: dayjs().startOf('month').format('YYYY-MM-DD'),
    end_date: dayjs().endOf('month').format('YYYY-MM-DD'),
  });
  const [monthChanged, setMonthChanged] = React.useState(false);
  const [highlightDates, setHighlightDates] = React.useState([]);
  const [totalAttendance, setTotalAttendance] = React.useState({});
  const [absentClicked, setAbsentClicked] = React.useState(false);
  const calendarRef = useRef();
  const [advanceDate, setAdvanceDate] = React.useState(
    dayjs().add(1, 'day').format('YYYY-MM-DD'),
  );
  const [toggleAdvanceDateSelection, setToggleAdvanceDateSelection] =
    React.useState(false);

  const [attendance, setAttendance] = React.useState({
    time_stamp_list: [],
    staff_id: '',
    is_present: true,
    updated: false,
    loading: false,
  });
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }),
  );

  useEffect(() => {
    if (selectedStaff) {
      getStaffDetails();
      getAttendance();
    }
  }, [selectedStaff, monthChanged]);

  useEffect(() => {
    if (selectedStaff) {
      submitForm(false);
    }
  }, [absentClicked]);

  const handleDate = (e) => {
    const startDate = dayjs(e.startStr).format('YYYY-MM-DD');
    const endDate = dayjs(e.endStr).subtract(1, 'day').format('YYYY-MM-DD');
    // get list of dates between start and end date
    const dateList = [];
    let currentDate = startDate;
    while (currentDate <= endDate) {
      dateList.push(currentDate);
      currentDate = dayjs(currentDate).add(1, 'day').format('YYYY-MM-DD');
    }
    setAttendance((prevState) => ({ ...prevState, time_stamp_list: dateList }));
  };

  const handleStaffChange = (e) => {
    setSelectedStaff(e.value);
    setAttendance((prevState) => ({ ...prevState, staff_id: e.value }));
  };

  const getAttendance = () => {
    services
      .getAttendance(
        selectedStaff,
        selectedDate.start_date,
        selectedDate.end_date,
      )
      .then((response) => {
        const tmp = response.data.data;
        const attendanceDays = tmp.data;
        const totalHours = attendanceDays.reduce(
          (acc, curr) => acc + curr.duty_hours,
          0,
        );

        const highlightedDates = [];
        attendanceDays.map((item) => {
          const salary_type = item.salary_type.salary_type;
          let duty_status = 'ABSENT';
          let backgroundColor = '#d6f3e9';
          if (salary_type === 'Fixed') {
            duty_status =
              item.duty_status == 'ABSENT'
                ? 'ABSENT'
                : `${item.duty_status} DAY`;
          } else if (salary_type === 'Hourly') {
            duty_status = item.is_present
              ? `${item.duty_hours} HOURS`
              : 'ABSENT';
          } else {
            // join array in new line

            duty_status = item.shifts
              .map((shift) => shift.shift)
              .join(', \r\n');
          }

          if (!item.is_present) {
            backgroundColor = '#fde1e1';
            duty_status = 'ABSENT';
          } else if (duty_status === 'HALF DAY') {
            backgroundColor = '#f7f7bb';
          } else if (duty_status === 'FULL DAY') {
            backgroundColor = '#d6f3e9';
          } else if (duty_status === 'PAID LEAVE DAY') {
            backgroundColor = '#00ff0080';
          }
          highlightedDates.push({
            date: dayjs(item.time_stamp).format('YYYY-MM-DD'),
            display: 'background',
            backgroundColor: backgroundColor,
            title: duty_status,
          });
        });
        setTotalAttendance((prevState) => ({
          ...prevState,
          present_days: tmp.present_days,
          absent_days: tmp.absent_days,
          half_days: tmp.half_days,
          total_hours: totalHours,
          shifts: tmp.shifts,
          total_days: tmp.total_calculated_days_for_month,
        }));
        setHighlightDates(highlightedDates);
      });
  };

  const handleMonthChange = (arg) => {
    const startDate = dayjs(arg.view.currentStart)
      .startOf('month')
      .format('YYYY-MM-DD');
    const endDate = dayjs(arg.view.currentStart)
      .endOf('month')
      .format('YYYY-MM-DD');
    const currentStartDate = dayjs(selectedDate.start_date).format(
      'YYYY-MM-DD',
    );
    const currentEndDate = dayjs(selectedDate.end_date).format('YYYY-MM-DD');

    // getPublicHolidays(startDate, endDate);

    if (startDate !== currentStartDate || endDate !== currentEndDate) {
      setSelectedDate((prevState) => ({
        ...prevState,
        start_date: startDate,
        end_date: endDate,
      }));
      setMonthChanged(!monthChanged);
    }
  };

  const handleAttendanceData = (data) => {
    if (staffDetails.salary_structure.salary_type.name === 'Fixed') {
      if (data.value == 'ABSENT') {
        setAttendance((prevState) => ({
          ...prevState,
          is_present: false,
          duty_status: null,
        }));
      } else {
        setAttendance((prevState) => ({
          ...prevState,
          is_present: true,
          duty_status: data.value,
        }));
      }
    } else if (staffDetails.salary_structure.salary_type.name === 'Hourly') {
      setAttendance({
        ...attendance,
        is_present: true,
        duty_hours: data,
      });
    } else {
      setAttendance((prevState) => ({
        ...prevState,
        is_present: true,
        shift_list: data,
      }));
    }
  };

  const getStaffDetails = () => {
    services
      .getStaffDetails(selectedStaff)
      .then((res) => {
        setStaffDetails(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const submitForm = (e) => {
    e && e!== false && e.preventDefault();
    if (e !== false && !simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }

    if (!attendance.time_stamp_list.length) {
      toast.error('Please select dates');
      return;
    }

    if (attendance.is_present == null) {
      toast.error(
        'Please select an attendance type to mark or click on mark absent button to mark absent',
      );
      return;
    }
    setIsSubmitting(true);

    const calendarApi = calendarRef.current.getApi();
    

    const url = `${process.env.API_URL}/staff-management/bunk/attendance/`;
    const method = 'post';

    const headers = authHeader(1);
    // if uuid field in all items in shift_list items is empty string, then remove the shift_list key from attendance object
    if (
      attendance.shift_list &&
      attendance.shift_list.every((item) => item.uuid === '')
    ) {
      delete attendance.shift_list;
    }

    axios
      .request({
        url,
        method,
        headers,
        data: attendance,
      })
      .then(() => {
        toast.success('Updated attendance successfully');
        setIsSubmitting(false);
        setMonthChanged(!monthChanged);
        calendarApi.unselect();
        setAttendance((prevState) => ({
          ...prevState,
          time_stamp_list: [],
          is_present: null,
          updated: !attendance.updated,
        }));
        simpleValidator.current.hideMessages();
      })
      .catch((error) => {
        setIsSubmitting(false);
        console.log(error);
        toast.error('Error. Please try again');
      });
  };

  const markAbsent = () => {
    if (!selectedStaff && attendance.time_stamp_list.length === 0) {
      toast.error('Please select staff & dates');
      return;
    }
    setAttendance((prevState) => ({
      ...prevState,
      is_present: false,
      duty_status: null,
      shift_list: null,
    }));

    setAbsentClicked(!absentClicked);
  };

  const toggleAdvanceDate = (e) => {
    setToggleAdvanceDateSelection(e.target.checked);
    if (!e.target.checked) {
      setAdvanceDate(dayjs().add(1, 'day').format('YYYY-MM-DD'));
    } else {
      setAdvanceDate(dayjs().add(99, 'year').format('YYYY-MM-DD'));
    }
  };

  const customButtons = {
    markAbsent: {
      text: 'Mark Absent',
      click: markAbsent,
    },
  };

  return (
    <>
      <section className="widget full-width">
        <header>
          <h3> Attendance </h3>
          <div className="actions">
            <div className="input-wrapper  toggle-element">
              <span className="label">Allow future date selection </span>
              <ToggleSwitch
                id="edit_is_bowser"
                name="is_bowser"
                checked={toggleAdvanceDateSelection}
                onChange={toggleAdvanceDate}
              />
            </div>
          </div>
        </header>
        <main className="two-col">
          <div className="col">
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin]}
              select={handleDate}
              selectable={true}
              datesSet={handleMonthChange}
              events={highlightDates}
              unselectAuto={false}
              ref={calendarRef}
              validRange={{
                end: advanceDate,
              }}
              customButtons={customButtons}
              headerToolbar={{
                left: 'title',
                center: 'markAbsent',
                right: 'prev,next',
              }}
            />
          </div>
          <div className="col">
            <form>
              <div className="input-wrapper with-label ">
                <label className="label"> Staff</label>
                <StaffList
                  value={selectedStaff}
                  onChange={handleStaffChange}
                  staffType={['Cashiers', 'Managers', 'other_staff']}
                />
              </div>
              {selectedStaff && (
                <>
                  <AttendanceFields
                    salary={staffDetails.salary_structure}
                    onDataChange={handleAttendanceData}
                    simpleValidator={simpleValidator}
                    key={attendance.updated}
                  />
                  <div className="form-actions mt-20">
                    <button
                      type="button"
                      className={`submit ${isSubmitting ? 'loading' : ''}`}
                      onClick={submitForm}
                    >
                      Submit
                    </button>
                  </div>
                </>
              )}
            </form>
            {selectedStaff && (
              <>
                <div className="form-summary mt-20">
                  <ul>
                    {(() => {
                      if (Object.keys(staffDetails).length == 0) {
                        return <></>;
                      }

                      if (
                        staffDetails.salary_structure.salary_type.name ===
                        'Fixed'
                      ) {
                        return (
                          <>
                            <li>
                              <span className="title ">
                                Total no. of full days worked for the month:
                              </span>
                              <span className="detail alert-green">
                                {totalAttendance.present_days -
                                  totalAttendance.half_days}
                              </span>
                            </li>
                            <li>
                              <span className="title ">
                                Total no. of days absent for the month:
                              </span>
                              <span className="detail alert-red">
                                {totalAttendance.absent_days}
                              </span>
                            </li>
                            <li>
                              <span className="title ">
                                Total no. of half days worked for the month:
                              </span>
                              <span className="detail alert-orange">
                                {totalAttendance.half_days}
                              </span>
                            </li>
                            <li>
                              <span className="title ">
                                Salary calculated days for the month:
                              </span>
                              <span className="detail alert-brand">
                                {totalAttendance.total_days}
                              </span>
                            </li>
                          </>
                        );
                      } else if (
                        staffDetails.salary_structure.salary_type.name ===
                        'Hourly'
                      ) {
                        return (
                          <>
                            <li>
                              <span className="title ">
                                Total no. of days present for the month:
                              </span>
                              <span className="detail alert-green">
                                {totalAttendance.present_days}
                              </span>
                            </li>
                            <li>
                              <span className="title">
                                Salary calculated hours for the month:
                              </span>
                              <span className="detail alert-green">
                                {totalAttendance.total_hours}
                              </span>
                            </li>
                          </>
                        );
                      } else if (
                        staffDetails.salary_structure.salary_type.name ===
                        'Shift Wise' &&
                        totalAttendance.shifts
                      ) {
                        return (
                          <>
                            <li>
                              <table className="dsr">
                                <thead>
                                  <tr>
                                    <th>Shift name</th>
                                    <th>No. of shifts worked</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {totalAttendance.shifts.map(
                                    (shift, index) => (
                                      <tr key={index}>
                                        <td>{shift.name}</td>
                                        <td>{shift.number_of_shifts}</td>
                                      </tr>
                                    ),
                                  )}
                                </tbody>
                              </table>
                            </li>
                          </>
                        );
                      }
                    })()}
                  </ul>
                </div>
              </>
            )}
          </div>
        </main>
      </section>
    </>
  );
};
export { Attendance };