import React, { useRef, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import Geonames from 'geonames.js';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import services from '@C/CreditCustomers/_utils/data';
import { ToggleSwitch } from '@C/FormElements';
import { CountryList } from '@C/Utils';
import { authHeader } from '@H';
import { authService } from '@S';
import { useGlobalStore } from '@stores/globalStore';

import { AllCreditCustomer } from './AllCreditCustomer';

const geonames = Geonames({
  username: 'hectonetworks',
  lan: 'en',
  encoding: 'JSON',
});

const CreditCustomerList = () => {
  const setCreditCustomers = useGlobalStore(
    (state) => state.setCreditCustomers,
  );
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }),
  );
  const ccRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [customer, setCustomer] = useState({
    loading: false,
    updated: false,
    first_name: '',
    last_name: '',
    password: '',
    is_password_update: false,
    mobile: '',
    email: '',
    gstn: '',
    pan: '',
    user_role: 104,
    credit_customer: {
      vehicle: [
        {
          vehicle_no: '',
          driver_name: '',
          mobile: '',
        },
      ],
      billing_cycle: {
        start_date: dayjs().format('YYYY-MM-DD'),
        payment_period: '',
        limit: '',
        balance: '',
        outstanding: '',
      },
      address: {
        country: '',
        state: '',
        city: '',
        pincode: '',
        country_code: '',
        full_address: '',
      },
    },
  });

  const toggleWidget = () => {
    setToggle(!toggle);
  };

  const togglePasswordUpdate = () => {
    setCustomer((customer) => ({
      ...customer,
      is_password_update: !customer.is_password_update,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomer((customer) => ({
      ...customer,
      [name]: value,
    }));
  };

  const handleBillingCycleData = (e) => {
    const { name, value } = e.target;
    setCustomer((customer) => ({
      ...customer,
      credit_customer: {
        ...customer.credit_customer,
        billing_cycle: {
          ...customer.credit_customer.billing_cycle,
          [name]: value,
        },
      },
    }));
  };

  const handleAddressData = (e) => {
    const { name, value } = e.target;
    setCustomer((customer) => ({
      ...customer,
      credit_customer: {
        ...customer.credit_customer,
        address: {
          ...customer.credit_customer.address,
          [name]: value,
        },
      },
    }));
  };

  const handleCountryChange = (e) => {
    setCustomer((customer) => ({
      ...customer,
      credit_customer: {
        ...customer.credit_customer,
        address: {
          ...customer.credit_customer.address,
          country: e?.value,
        },
      },
    }));
  };

  const handlePincodeChange = (e) => {
    const postalcode = e.target.value;
    setCustomer((customer) => ({
      ...customer,
      credit_customer: {
        ...customer.credit_customer,
        address: {
          ...customer.credit_customer.address,
          pincode: postalcode,
          country_code: '',
        },
      },
    }));
    if (postalcode.length > 5) {
      setCustomer((customer) => ({
        ...customer,
        loading: true,
      }));

      geonames
        .postalCodeSearch({ postalcode: `${postalcode}` })
        .then((response) => {
          if (response.postalCodes.length > 0) {
            setCustomer((customer) => ({
              ...customer,
              credit_customer: {
                ...customer.credit_customer,
                address: {
                  ...customer.credit_customer.address,
                  state: response.postalCodes[0].adminName1,
                  city: response.postalCodes[0].adminName2,
                  country_code: response.postalCodes[0].countryCode,
                },
              },
              loading: false,
            }));
          } else {
            setCustomer((customer) => ({
              ...customer,
              loading: false,
            }));
            toast.error('Invalid Pincode');
          }
        })
        .catch((error) => {
          setCustomer((customer) => ({
            ...customer,
            loading: false,
          }));
          toast.error('Invalid Pincode');
        });
    }
  };

  const handleEditMode = (item) => {
    setCustomer({
      uuid: item.uuid,
      first_name: item.basic_details.first_name,
      last_name: item.basic_details.last_name,
      mobile: item.basic_details.mobile,
      email: item.basic_details.email,
      gstn: item.gstn || '',
      pan: item.pan || '',
      user_role: 104,
      credit_customer: {
        vehicle: [
          {
            vehicle_no: '',
            driver_name: '',
            mobile: '',
          },
        ],
        billing_cycle: {
          start_date: item.billing_cycle.start_date,
          payment_period: item.billing_cycle.payment_period,
          limit: item.billing_cycle.payment_range,
          outstanding: item.billing_cycle.outstanding,
        },
        address: {
          country: item.address.country.uuid,
          state: item.address.state || '',
          city: item.address.city,
          pincode: item.address.pincode || '',
          full_address: item.address.full_address || '',
        },
      },
    });
    setToggle(true);
    window.scrollTo({ behavior: 'smooth', top: ccRef.current.offsetTop - 100 });
    setEditMode(true);
  };

  const cancelEditMode = () => {
    setCustomer({
      loading: false,
      updated: false,
      first_name: '',
      last_name: '',
      password: '',
      is_password_update: false,
      mobile: '',
      email: '',
      gstn: '',
      pan: '',
      user_role: 104,
      credit_customer: {
        vehicle: [
          {
            vehicle_no: '',
            driver_name: '',
            mobile: '',
          },
        ],
        billing_cycle: {
          start_date:
            new Date().getFullYear() +
            '-' +
            ('0' + (new Date().getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + new Date().getDate()).slice(-2),
          payment_period: '',
          limit: '',
          balance: '',
          outstanding: '',
        },
        address: {
          country: '',
          state: '',
          city: '',
          pincode: '',
          full_address: '',
        },
      },
    });
    setToggle(false);
    setEditMode(false);
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    setIsSubmitting(true);
    let formData = customer;
    const headers = authHeader(authService.currentAccountID);

    let method = 'post';
    let url = `${process.env.API_URL}/users/register/`;
    if (editMode) {
      method = 'put';
      url = `${process.env.API_URL}/credit-customer/bunk/customer/`;
      formData = {
        uuid: customer.uuid,
        first_name: customer.first_name,
        last_name: customer.last_name,
        mobile: customer.mobile,
        password: customer.password,
        is_password_update: customer.is_password_update,
        email: customer.email,
        gstn: customer.gstn,
        pan: customer.pan,
        user_role: 104,
        billing_cycle: {
          start_date: customer.credit_customer.billing_cycle.start_date,
          payment_period: customer.credit_customer.billing_cycle.payment_period,
          limit: customer.credit_customer.billing_cycle.limit,
          outstanding: customer.credit_customer.billing_cycle.outstanding,
        },
        address: {
          country: customer.credit_customer.address.country,
          state: customer.credit_customer.address.state,
          city: customer.credit_customer.address.city,
          pincode: customer.credit_customer.address.pincode,
          full_address: customer.credit_customer.address.full_address,
        },
      };
    }

    axios({
      method: method,
      url: url,
      data: formData,
      headers: headers,
    })
      .then((response) => {
        toast.success(
          `Credit customer ${editMode ? 'updated' : 'added'} successfully`,
        );
        window.scrollTo({
          behavior: 'smooth',
          top: ccRef.current.offsetTop - 150,
        });
        setIsSubmitting(false);
        setCustomer((customer) => ({
          ...customer,
          updated: !customer.updated,
          first_name: '',
          last_name: '',
          password: '',
          mobile: '',
          email: '',
          gstn: '',
          pan: '',
          user_role: 104,
          credit_customer: {
            vehicle: [
              {
                vehicle_no: '',
                driver_name: '',
                mobile: '',
              },
            ],
            billing_cycle: {
              start_date:
                new Date().getFullYear() +
                '-' +
                ('0' + (new Date().getMonth() + 1)).slice(-2) +
                '-' +
                ('0' + new Date().getDate()).slice(-2),
              payment_period: '',
              limit: '',
              balance: '',
              outstanding: '',
            },
            address: {
              country: '',
              state: '',
              city: '',
              pincode: '',
              full_address: '',
            },
          },
        }));

        updateCustomerList();
        simpleValidator.current.hideMessages();
        forceUpdate(1);
        setEditMode(false);
        setToggle(false);
      })

      .catch((error) => {
        setIsSubmitting(false);
        toast.error(error.response.data.message);
      });
  };

  const updateCustomerList = () => {
    services
      .getCreditCustomerList()
      .then((response) => {
        setCreditCustomers(response.data.data.results);
      })
      .catch((error) => {
        console.log(error);
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <section
        className={`widget full-width toggle-widget ${toggle ? 'active' : ''}`}
        ref={ccRef}
      >
        <header className="form-header" onClick={toggleWidget}>
          <h3>{`${editMode ? 'Edit' : 'Add'} Credit Customer`}</h3>
        </header>

        <main>
          <form className="inline">
            <div className="input-wrapper with-label">
              <label>Date</label>
              <input
                type="date"
                name="start_date"
                onChange={handleBillingCycleData}
                value={customer.credit_customer.billing_cycle.start_date}
              />
            </div>
            <div className="input-wrapper with-label">
              <label>Customer First Name</label>
              {simpleValidator.current.message(
                'Customer Name',
                customer.first_name,
                'required|alpha_num_space|max:30,string',
              )}
              <input
                type="text"
                id="first_name"
                name="first_name"
                onChange={handleChange}
                value={customer.first_name}
              />
            </div>
            <div className="input-wrapper with-label">
              <label>Customer Last Name</label>
              <input
                type="text"
                id="last_name"
                name="last_name"
                onChange={handleChange}
                value={customer.last_name}
              />
            </div>
            <div className="input-wrapper with-label">
              <label>Mobile number</label>
              {simpleValidator.current.message(
                'Customer number',
                customer.mobile,
                'required|numeric|min:10,array|max:10,array',
              )}
              <input
                type="text"
                id="mobile"
                name="mobile"
                onChange={handleChange}
                value={customer.mobile}
              />
            </div>
            {!editMode && (
              <div
                className={
                  showPassword
                    ? 'input-wrapper visible with-label'
                    : 'input-wrapper with-label'
                }
              >
                <label> Password </label>
                <input
                  type={showPassword ? 'text' : 'password'}
                  value={customer.password}
                  onChange={handleChange}
                  name="password"
                />
                <span
                  className="show-psswd"
                  onClick={() => setShowPassword(!showPassword)}
                />
              </div>
            )}
            <div className="input-wrapper with-label">
              <label>Email</label>
              {simpleValidator.current.message(
                'Customer email',
                customer.email,
                'email',
              )}
              <input
                type="text"
                id="email"
                name="email"
                onChange={handleChange}
                value={customer.email}
              />
            </div>
            <div className="input-wrapper with-label">
              <label>GST number</label>
              <input
                type="text"
                id="gstn"
                name="gstn"
                onChange={handleChange}
                value={customer.gstn}
              />
            </div>
            <div className="input-wrapper with-label">
              <label>PAN</label>
              <input
                type="text"
                id="pan"
                name="pan"
                onChange={handleChange}
                value={customer.pan}
              />
            </div>
            <div className="input-wrapper with-label">
              <label>Billing cycle (in days)</label>
              {simpleValidator.current.message(
                'Billing cycle',
                customer.credit_customer.billing_cycle.payment_period,
                'required',
              )}
              <input
                type="text"
                onChange={handleBillingCycleData}
                value={customer.credit_customer.billing_cycle.payment_period}
                name="payment_period"
              />
            </div>
            <div className="input-wrapper with-label">
              <label>Limit</label>
              {simpleValidator.current.message(
                'Limit',
                customer.credit_customer.billing_cycle.limit,
                'required|numeric',
              )}
              <input
                type="text"
                id="limit"
                name="limit"
                onChange={handleBillingCycleData}
                value={customer.credit_customer.billing_cycle.limit}
              />
            </div>
            <div className="input-wrapper with-label">
              <label>Opening balance</label>
              {simpleValidator.current.message(
                'Outstanding',
                customer.credit_customer.billing_cycle.outstanding,
                'required|numeric',
              )}
              <input
                type="text"
                id="outstanding"
                name="outstanding"
                onChange={handleBillingCycleData}
                value={customer.credit_customer.billing_cycle.outstanding}
              />
            </div>
            <div
              className={
                customer.loading
                  ? 'input-wrapper with-label loading'
                  : 'input-wrapper with-label'
              }
            >
              <label>Pincode</label>
              {simpleValidator.current.message(
                'Pincode',
                customer.credit_customer.address.pincode,
                'required|numeric',
              )}
              <input
                type="text"
                name="pincode"
                onChange={handlePincodeChange}
                value={customer.credit_customer.address.pincode}
              />
            </div>
            <div className="input-wrapper with-label">
              <label>City</label>
              <input
                type="text"
                name="city"
                onChange={handleAddressData}
                value={customer.credit_customer.address.city}
                disabled={customer.loading}
              />
            </div>
            <div className="input-wrapper with-label">
              <label>State</label>
              <input
                type="text"
                name="state"
                onChange={handleAddressData}
                value={customer.credit_customer.address.state}
                disabled={customer.loading}
              />
            </div>
            <div className="input-wrapper with-label">
              <label>Country</label>
              <CountryList
                handleCountryChange={handleCountryChange}
                value={customer.credit_customer.address.country}
                code={customer.credit_customer.address.country_code}
              />
            </div>
            <div className="input-wrapper with-label">
              <label> Full address</label>
              <input
                type="text"
                name="full_address"
                onChange={handleAddressData}
                value={customer.credit_customer.address.full_address}
                disabled={customer.loading}
              />
            </div>
            {editMode && (
              <>
                <div className="input-wrapper  toggle-element">
                  <span className="label"> Update password: </span>
                  <ToggleSwitch
                    did={0}
                    id="updatePassword"
                    name="updatePassword"
                    disabled={!editMode}
                    checked={customer.is_password_update}
                    onChange={togglePasswordUpdate}
                  />
                </div>

                <div
                  className={
                    showPassword
                      ? 'input-wrapper visible with-label'
                      : 'input-wrapper with-label'
                  }
                >
                  <label> password </label>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    disabled={!customer.is_password_update}
                    value={customer.password}
                    name="password"
                    onChange={handleChange}
                  />
                  <span
                    className="show-psswd"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </div>
              </>
            )}

            <div className="item-actions">
              <button
                className={`submit ${isSubmitting ? 'loading' : ''}`}
                onClick={submitForm}
              >
                Submit
              </button>
              {editMode && (
                <button className="red-btn" onClick={cancelEditMode}>
                  Cancel
                </button>
              )}
            </div>
          </form>
        </main>
      </section>
      <AllCreditCustomer key={customer.updated} onEdit={handleEditMode} />
    </>
  );
};

export { CreditCustomerList };
