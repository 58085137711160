import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';

import services from '@C/Lubes/_utils/data';
import { TableGrid } from '@C/TableGrid';
import { authHeader } from '@H';

const col = [
  {
    name: 'Name',
    key: 'all',
    formatter(props) {
      return (
        <>
          {props.first_name} {props.last_name}
        </>
      );
    },
  },
  {
    name: 'Address',
    key: 'all',
    formatter(props) {
      return (
        <>
          {props.city} {props.state} {props.country}
        </>
      );
    },
  },
  {
    name: 'Mobile',
    key: 'mobile',
  },
  {
    name: 'GST #',
    key: 'gstn',
  },
  {
    name: 'Email',
    key: 'email',
  },
  {
    name: 'Outstanding',
    key: 'outstanding',
    type: 'currency',
  },
];

const enabledActions = [
  {
    action: 'Edit',
    roles: ['Site Admin', 'Dealer'],
  },
  {
    action: 'Delete',
    roles: ['Site Admin', 'Dealer'],
  },
];

class VendorList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      selectedId: '',
      offset: 0,
      limit: 20,
      count: 0,
    };
  }

  componentDidMount() {
    this.getVendorList();
  }

  getVendorList = () => {
    services
      .getVendorList()
      .then((response) => {
        this.setState({
          list: response.data.data.results,
          count: response.data.data.count,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handlePagination = (offset, limit) => {
    this.setState({ offset, limit }, () => {
      this.getVendorList();
    });
  };

  confirmDelete = (id) => {
    confirmAlert({
      title: 'Confirm vendor deletion',
      message: 'Are you sure',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteItem(id),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  deleteItem = (id) => {
    const headers = authHeader(1);
    axios
      .delete(
        `${process.env.API_URL}/lubes/bunk/vendor/?vendor_id=${id.uuid}`,
        {
          headers,
        },
      )
      .then(() => {
        toast.success('Vendor deleted successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        this.getVendorList();
      });
  };

  render() {
    const { list } = this.state;

    return (
      <>
        <section className="widget full-width">
          <header>
            <h3>Vendors/Supplier List</h3>
          </header>
          <main>
            <TableGrid
              col={col}
              row={list}
              offset={this.state.offset}
              count={this.state.count}
              limit={this.state.limit}
              handlePagination={this.handlePagination}
              onEdit={this.props.onEdit}
              onDelete={this.confirmDelete}
              enabledActions={enabledActions}
              exportOptions={{
                fileName: `Vendors & Suppliers List`,
                reportName: 'Vendors & Suppliers',
                reportDate: `Date - ${dayjs().format('D MMM YY')}`,
                reportTotal: '',
                reportQuantity: '',
                reportSales: '',
                reportTesting: '',
                reportBowser: '',
                reportCustomerName: '',
                reportGst: '',
                reportOpeningBalance: '',
                reportClosingBalance: '',
                reportSaleTotal: '',
                reportTCSTotal: '',
              }}
            />
          </main>
        </section>
      </>
    );
  }
}

export { VendorList };
