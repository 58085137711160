import React, { Component, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import { Fields, Modes } from '@C/Banking/';
import { SalaryType } from '@C/SalaryType/';
import services from '@C/StaffManagement/_utils/data';
import SalaryList from '@C/StaffManagement/RouteComponents/Salary/SalaryList';
import { FilteredStaffList, StaffList } from '@C/Utils';
import { authHeader } from '@H';

import '@C/StaffManagement/RouteComponents/AdvanceReturn/styles.scoped.scss';

const Salary = () => {
  const [, forceUpdate] = useState();
  const editRef = useRef(null);
  const simpleValidator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }),
  );
  const [salaryMonth, setSalaryMonth] = useState(dayjs().format('YYYY-MM'));
  const [canEdit, setCanEdit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [state, setState] = useState({
    time_stamp: dayjs().format('YYYY-MM-DD'),
    staff_id: '',
    month: dayjs().format('MMMM').toUpperCase(),
    year: dayjs().format('YYYY'),
    number_of_hours: 0,
    settlement_amount: 0,
    number_of_day_present: 0,
    number_of_shifts: 0,
    paid_amount: 0,
    ESI: 0,
    deduction: 0,
    bonus: 0,
    other_insurance: 0,
    professional_tax: 0,
    provided_fund_employee: 0,
    salary_given_by_id: '',
    checked: false,
    calculated_salary: 0,
    employee_number: '',
    mobile: '',
    editMode: false,
    notes: '',
    payment: {
      bank_id: null,
      payment_type: 'OFFLINE',
      source: 'CASH',
      check_date: '',
      check_number: '',
      mode: 'cash',
    },
    updated: false,
  });

  useEffect(() => {
    if (state.staff_id) {
      getStaffSalaryDetails();
      calculateTotalSalary();
    }
  }, [state.staff_id, salaryMonth]);

  useEffect(() => {
    if (state.staff_id) {
      calculateTotalSalary();
    }
  }, [
    state.calculated_salary,
    state.deduction,
    state.bonus,
    state.other_incentives,
    state.settlement_amount,
  ]);

  const getStaffSalaryDetails = () => {
    services
      .getStaffSalaryDetails(state)
      .then((res) => {
        if (res.data.data.status == 400) {
          setState((prevState) => ({
            ...prevState,
            salary_type: '',
          }));
          setCanEdit(false);
          toast.error('Salary structure not set for the selected staff');
          return;
        } else {
          const tmp = res.data.data;
          setState((prevState) => ({
            ...prevState,
            employee_number: tmp.employee.employee_number,
            mobile: tmp.employee.mobile,
            number_of_day_present: tmp.salary.calculated_days,
            number_of_hours: tmp.salary.number_of_hours,
            shift_details: tmp.salary.shifts,
            advance_taken: tmp.salary.advance_taken,
            calculated_salary: tmp.salary.salary.toFixed(2),
            salary_structure: tmp.salary_structure,
            salary_type: tmp.salary_structure.salary_type.name,
            bonus: tmp.salary.bonus,
            deduction: tmp.salary.deduction,
            other_incentives: tmp.salary.other_incentives,
            settlement_amount: tmp.salary.settled_amount,
            salary_given_by_id: tmp.salary.salary_given_by.uuid,
            time_stamp: dayjs(tmp.salary.time_stamp || dayjs()).format(
              'YYYY-MM-DD',
            ),
          }));
          setCanEdit(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const calculateTotalSalary = () => {
    const salary = +state.calculated_salary || 0;
    const deduction = +state.deduction || 0;
    const bonus = +state.bonus || 0;
    const other_incentives = +state.other_incentives || 0;
    const settlement_amount = +state.settlement_amount || 0;
    const total =
      salary + bonus + other_incentives - deduction - settlement_amount;
    setState((prevState) => ({
      ...prevState,
      paid_amount: total.toFixed(2),
    }));
  };

  const handleSalaryMonth = (date) => {
    setSalaryMonth(dayjs(date).format('YYYY-MM'));
    const month = dayjs(date).format('MMMM').toUpperCase();
    const year = dayjs(date).format('YYYY');
    setState((prevState) => ({
      ...prevState,
      month: month,
      year: year,
    }));
  };

  const handleTimeStamp = (e) => {
    setState((prevState) => ({
      ...prevState,
      time_stamp: dayjs(e.target.value).format('YYYY-MM-DD'),
    }));
  };

  const handleChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleNotesChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleProviderChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      salary_given_by_id: e.value,
    }));
  };

  const handleStaffChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      staff_id: e.value,
    }));
  };

  const handlePaymentSelect = (mode, i) => {
    const list = { ...state.payment };
    list.mode = mode;
    if (mode == 'cash') {
      list.payment_type = 'OFFLINE';
      list.source = 'CASH';
    }
    setState((prevState) => ({
      ...prevState,
      payment: list,
    }));
  };

  const editMode = (item) => {
    setState((prevState) => ({
      ...prevState,
      staff_id: item.employee.uuid,
      month: item.month,
      year: item.year,
      id: item.uuid,
      editMode: true,
    }));
    window.scrollTo({
      behavior: 'smooth',
      top: editRef.current.offsetTop - 100,
    });
  };

  const onDataChange = (e, i) => {
    const list = { ...state.payment };
    list.bank_id = e.bank_id;
    list.check_number = e.bank_reference_id;
    list.check_date = e.check_date;
    list.payment_type = e.payment_method;
    list.source = e.transaction_source;
    setState((prevState) => ({
      ...prevState,
      payment: list,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      scrollToTop();
      return;
    }
    setIsSubmitting(true);
    const formData = state;

    let method = 'post';
    const url = `${process.env.API_URL}/staff-management/bunk/salary/`;
    if (state.editMode) {
      method = 'put';
      formData.uuid = state.id;
    }

    const headers = authHeader(1);
    axios({
      method: method,
      url: url,
      data: formData,
      headers: headers,
    })
      .then((res) => {
        toast.success('Transaction updated successfully');
        window.scrollTo({
          behavior: 'smooth',
          top: editRef.current.offsetTop - 150,
        });
        setIsSubmitting(false);
        setSalaryMonth(dayjs().format('YYYY-MM'));
        setState({
          time_stamp: dayjs().format('YYYY-MM-DD'),
          staff_id: '',
          month: dayjs().format('MMMM'),
          year: dayjs().format('YYYY'),
          number_of_hours: 0,
          settlement_amount: 0,
          number_of_day_present: 0,
          number_of_shifts: 0,
          paid_amount: 0,
          ESI: 0,
          deduction: 0,
          bonus: 0,
          other_insurance: 0,
          professional_tax: 0,
          provided_fund_employee: 0,
          salary_given_by_id: '',
          checked: false,
          calculated_salary: 0,
          employee_number: '',
          mobile: '',
          editMode: false,
          other_incentives: 0,
          notes: '',
          payment: {
            bank_id: null,
            payment_type: 'OFFLINE',
            source: 'CASH',
            check_date: '',
            check_number: '',
            mode: 'cash',
          },
          updated: !state.updated,
        });
        setCanEdit(false);
        simpleValidator.current.hideMessages();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setIsSubmitting(false);
      });
  };

  const scrollToTop = () => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
  };

  return (
    <>
      <section className="widget full-width" ref={editRef}>
        <header>
          <h3> Staff Salary </h3>
        </header>
        <main className="two-col">
          {!state.staff_id && (
            <div className="notification-alert notification-alert__error">
              Please select a staff
            </div>
          )}
          {state.staff_id && state.salary_type == '' && (
            <div className="notification-alert notification-alert__error">
              Salary structure not set for the selected staff
            </div>
          )}
          <div className="col">
            <div className="col-header">
              <h4>Employee Details</h4>
            </div>
            <div className="inline-form">
              <div className="input-wrapper with-label ">
                <label>Staff </label>
                {simpleValidator.current.message(
                  'state.staff_id',
                  state.staff_id,
                  'required',
                )}
                <StaffList
                  onChange={handleStaffChange}
                  value={state.staff_id}
                  disabled={state.editMode}
                  staffType={['Cashiers', 'Managers', 'other_staff']}
                />
              </div>
              <div className="input-wrapper with-label ">
                <label> Month </label>
                <DatePicker
                  selected={new Date(salaryMonth)}
                  dateFormat="MMMM yyyy"
                  showMonthYearPicker
                  onSelect={handleSalaryMonth}
                  disabled={!canEdit || state.editMode}
                  popperPlacement='bottom-start'
                />
              </div>
              <div className="input-wrapper with-label ">
                <label> Date</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={
                    state.time_stamp
                      ? state.time_stamp
                      : dayjs().format('YYYY-MM-DD')
                  }
                  onChange={handleTimeStamp}
                  disabled={!canEdit || state.editMode}
                />
              </div>
              <div className="input-wrapper with-label ">
                <label> Salary given by </label>
                {simpleValidator.current.message(
                  'state.salary_given_by_id',
                  state.salary_given_by_id,
                  'required',
                )}
                <FilteredStaffList
                  onChange={handleProviderChange}
                  value={state.salary_given_by_id}
                  disabled={!canEdit || state.editMode}
                />
              </div>

              <div className="input-wrapper with-label ">
                <label>Employee number </label>
                <input
                  type="text"
                  value={state?.employee_number || ''}
                  disabled
                />
              </div>
              <div className="input-wrapper with-label ">
                <label> Mobile number </label>
                <input type="text" value={state?.mobile || ''} disabled />
              </div>
              <div className="form-break">
                <div className="col-header">
                  <h4>Salary Details</h4>
                </div>
                <div className="inline-form">
                  <div className="input-wrapper with-label">
                    <label> Salary type </label>
                    <SalaryType
                      value={state?.salary_structure?.salary_type?.uuid || ''}
                      disabled={true}
                    />
                  </div>

                  <div className="input-group">
                    <div className="input-wrapper with-label">
                      <label>Payment mode</label>
                      <Modes
                        handleModeChange={handlePaymentSelect}
                        value={state.payment?.mode || 'cash'}
                        disabled={!canEdit || state.editMode}
                      />
                    </div>
                    <Fields
                      method={state.payment?.mode}
                      onDataChange={onDataChange}
                      disabled={state.editMode}
                    />
                  </div>
                  <div className="input-wrapper with-label ">
                    <label> Total salary to be paid </label>
                    <input
                      type="text"
                      className="--green --bold"
                      disabled
                      value={state.paid_amount}
                      name="paid_amount"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="inline-form">
              {canEdit && (
                <div className="form-break">
                  <SalaryFields data={state} />
                </div>
              )}

              <div className="form-break">
                <div className="col-header">
                  <h4>Deductions & other incentives</h4>
                </div>
                <div className="inline-form">
                  <div className="input-wrapper with-label ">
                    <label className="label"> Salary advance taken </label>
                    <input type="text" value={state.advance_taken} disabled />
                  </div>
                  <div className="input-wrapper with-label ">
                    <label> Salary advance adjusted </label>
                    <input
                      type="text"
                      onChange={handleChange}
                      value={state.settlement_amount}
                      name="settlement_amount"
                      disabled={!canEdit}
                    />
                  </div>
                  <div className="input-wrapper with-label ">
                    <label> Incentives for the month </label>
                    <input
                      type="text"
                      onChange={handleChange}
                      value={state.other_incentives}
                      name="other_incentives"
                      disabled={!canEdit}
                    />
                  </div>
                  <div className="input-wrapper with-label ">
                    <label className="label"> Bonus </label>
                    <input
                      type="text"
                      onChange={handleChange}
                      value={state.bonus}
                      name="bonus"
                      disabled={!canEdit}
                    />
                  </div>
                  <div className="input-wrapper with-label ">
                    <label> Deductions </label>
                    <input
                      type="text"
                      onChange={handleChange}
                      value={state.deduction}
                      name="deduction"
                      disabled={!canEdit}
                    />
                  </div>
                  <div className="input-wrapper with-label ">
                    <label> Notes </label>
                    <input
                      type="text"
                      value={state.notes}
                      name="notes"
                      onChange={handleNotesChange}
                    />
                  </div>
                  <div className="form-actions">
                    <button
                      disabled={!canEdit}
                      className={`submit ${isSubmitting ? 'loading' : ''}`}
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </section>
      <SalaryList key={state.updated} onEdit={editMode} />
    </>
  );
};

const SalaryFields = ({ data }) => {
  switch (data.salary_type) {
    case 'Fixed':
      return <FixedFields data={data} />;
    case 'Hourly':
      return <HourlyFields data={data} />;
    case 'Shift Wise':
      return <ShiftWiseFields data={data} />;
    default:
      return <></>;
  }
};

const FixedFields = (props) => {
  return (
    <>
      <div className="col-header">
        <h4>Monthly fixed salary details</h4>
      </div>
      <div className="inline-form">
        <div className="input-wrapper with-label ">
          <label> Salary for the month </label>
          <input
            type="text"
            value={props.data.salary_structure?.amount}
            disabled
          />
        </div>
        <div className="input-wrapper with-label ">
          <label> Salary calculated days </label>
          <input
            type="text"
            disabled
            value={props.data.number_of_day_present}
          />
        </div>
        <div className="input-wrapper with-label ">
          <label> Salary amount </label>
          <input type="text" disabled value={props.data.calculated_salary} />
        </div>
      </div>
    </>
  );
};

const HourlyFields = (props) => {
  return (
    <>
      <div className="col-header">
        <h4>Hourly salary details</h4>
      </div>
      <div className="inline-form">
        <div className="input-wrapper with-label ">
          <label> Salary per hour </label>
          <input
            type="text"
            value={props.data.salary_structure?.amount}
            disabled
          />
        </div>
        <div className="input-wrapper with-label ">
          <label> Salary calculated hours </label>
          <input type="text" disabled value={props.data.number_of_hours} />
        </div>
        <div className="input-wrapper with-label ">
          <label> Salary amount </label>
          <input type="text" disabled value={props.data.calculated_salary} />
        </div>
      </div>
    </>
  );
};

const ShiftWiseFields = (props) => {
  return (
    <>
      <div className="col-header">
        <h4>Shift wise salary details</h4>
      </div>
      <div className="table-wrapper">
        <table className="dsr">
          <thead>
            <tr>
              <th>Shift name</th>
              <th>No. of shifts worked</th>
            </tr>
          </thead>
          <tbody>
            {props.data.shift_details.map((shift, index) => (
              <tr key={index}>
                <td>{shift.name}</td>
                <td>{shift.number_of_shifts}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="input-wrapper with-label ">
          <label> Salary amount </label>
          <input type="text" disabled value={props.data.calculated_salary} />
        </div>
      </div>
    </>
  );
};

class Form extends Component {
  constructor(props) {
    super(props);
    state = {};
  }

  render() {
    return (
      <>
        <section className="widget">
          <main>
            <form className="half-inline">
              <div className="input-wrapper with-label ">
                <label className="label"> Professional tax </label>
                <input
                  type="text"
                  onChange={handleChange}
                  value={state.amount}
                  id="amount"
                  name="amount"
                />
              </div>
              <div className="input-wrapper with-label ">
                <label className="label"> ESI </label>
                <input
                  type="text"
                  onChange={handleChange}
                  value={state.amount}
                  id="amount"
                  name="amount"
                />
              </div>
              <div className="input-wrapper with-label ">
                <label className="label"> Other insurance </label>
                <input
                  type="text"
                  onChange={handleChange}
                  value={state.amount}
                  id="amount"
                  name="amount"
                />
              </div>
            </form>
          </main>
        </section>
      </>
    );
  }
}

export { Salary };
