import React from 'react';
import dayjs from 'dayjs';
import { NavLink } from 'react-router-dom';

import { authService } from '@S';
import { useGlobalStore } from '@stores/globalStore';

import packageJson from '/package.json';

const Navbar = () => {
  const toggleSubMenu = (e) => {
    e.preventDefault();
    const el = e.target.nextSibling;
    el.classList.toggle('active');
  };

  const navigationData = useGlobalStore((state) => state.navigationData);

  const env = process.env.ENVIRONMENT;

  return (
    <div className="nav-wrapper expanded">
      <span className="app-version">v{packageJson.version}</span>
      <nav>
        <ul>
          {navigationData.map((link, index) => {
            if (link.enabled === false) return null;
            if (
              !link.roles.some(
                (item) => item.role_type === authService.currentUserRoleCode,
              )
            ) {
              return null;
            }
            if (!link.environment.some((item) => item.name === env)) {
              return null;
            }
            return (
              <li key={index}>
                <NavLink
                  activeClassName="active"
                  to={`${link.title.toLowerCase() === 'dsr' ? link.path + '/' + dayjs().format('YYYY-MM-DD') : link.path}`}
                >
                  <span className={link.icon} />
                  <span>{link.title}</span>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>

      <div className="wave-container">
        <div className="navbar-logo" />
        <div className="wave-wrapper">
          <div className="wave" />
          <div className="wave" />
        </div>
      </div>
    </div>
  );
};

export default Navbar;

export { Navbar };
