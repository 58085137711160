import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';

import { ProductList } from '@C/Lubes/_utils/';
import services from '@C/Lubes/_utils/data';
import { TableGrid } from '@C/TableGrid';
import { VendorList } from '@C/Utils';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { authHeader } from '@H';

const col = [
  {
    name: 'Date',
    key: 'time_stamp',
    sortable: true,
    type: 'date',
  },
  {
    name: 'Invoice No',
    key: 'invoice_number',
  },
  {
    name: 'Supplier',
    key: 'supplier_first_name',
  },
  {
    name: 'Product',
    key: 'lube_name',
  },
  {
    name: 'Quantity',
    key: 'no_of_units',
  },
  {
    name: 'Amount (Excl Tax)',
    key: 'purchased_amount',
    type: 'currency',
  },
  {
    name: 'GST',
    key: 'all',
    formatter(props) {
      return (
        <>
          {parseFloat(
            props.purchased_after_tax - props.purchased_amount,
          ).toFixed(2)}
        </>
      );
    },
    type: 'currency',
  },
  {
    name: 'TCS',
    key: 'tcs_amount',
    type: 'currency',
  },
  {
    name: 'Amount (Inc Tax)',
    key: 'purchased_after_tax',
    type: 'currency',
  },
  {
    name: 'Notes',
    key: 'notes',
  },
  // {
  //   name: 'Amount paid',
  //   key: 'paid_amount',
  //   type: 'currency',
  // },
  // {
  //   name: 'Payment method',
  //   key: 'payment_method',
  //   type: 'payment',
  //   formatter(props) {
  //     return (
  //       <>
  //         <PaymentMethodInfo
  //           source={props.source}
  //           method={props.payment_type}
  //           bank={props.payment?.bank}
  //           wallet={props.payment?.other_wallet}
  //         />
  //       </>
  //     );
  //   },
  // },
];

const enabledActions = [
  {
    action: 'Delete',
    roles: ['Manager', 'Dealer'],
  },
  {
    action: 'Edit',
    roles: ['Manager', 'Dealer'],
  },
];

class ListPurchase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saleList: [],
      selectedId: '',
      showModal: false,
      loading: false,
      start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      end_date: dayjs().format('YYYY-MM-DD'),
      product_id: '',
      vendor_id: '',
      invoice_id: '',
      limit: 20,
      offset: 1,
      count: 0,
    };

    this.limitInput = React.createRef();
  }

  componentDidMount() {
    this.getLubePurchaseList();
  }

  handlePagination = (e) => {
    this.setState({ offset: e }, function () {
      this.getLubePurchaseList();
    });
  };
  handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'start_date' && dayjs(value).isAfter(this.state.end_date)) {
      this.setState({ [name]: value, end_date: value });
    } else if (
      name === 'end_date' &&
      dayjs(value).isBefore(this.state.start_date)
    ) {
      this.setState({ [name]: value, start_date: value });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleVendorChange = (e) => {
    this.setState({ vendor_id: e.value });
  };

  handleBillChange = (e) => {
    this.setState({ invoice_id: e.target.value });
  };

  handleProductChange = (e) => {
    this.setState({ product_id: e.value });
  };

  handleQuickSearch = (e) => {
    e.preventDefault();
    if (
      this.limitInput.current.value === '' ||
      this.limitInput.current.value === '0'
    ) {
      return;
    }
    this.setState(
      { limit: this.limitInput.current.value, offset: 1 },
      function () {
        this.getLubePurchaseList();
      },
    );
  };

  getLubePurchaseList = () => {
    services
      .getLubePurchaseList(this.state)
      .then((response) => {
        this.setState({
          saleList: response.data.data.results,
          loading: false,
          count: response.data.data.count,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  resetFilter = () => {
    this.setState(
      {
        start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        end_date: dayjs().format('YYYY-MM-DD'),
        product_id: '',
        vendor_id: '',
        invoice_id: '',
        limit: 20,
        offset: 1,
        count: 0,
      },
      function () {
        this.getLubePurchaseList();
      },
    );
  };

  editItem = (selectedId) => {
    this.setState({ selectedId: selectedId, showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  confirmDelete = (item) => {
    confirmAlert({
      title: 'Confirm Lube Purchase deletion',
      message: 'Are you sure',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteItem(item),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  deleteItem = (item) => {
    const headers = authHeader(1);
    axios
      .delete(
        `${process.env.API_URL}/lubes/bunk/vendor/purchase/?invoice_id=${item.uuid}`,
        {
          headers,
        },
      )
      .then(() => {
        toast.success('Lube Purchase deleted successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        this.getLubePurchaseList();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  submitForm = (e) => {
    e.preventDefault();
    this.setState({ loading: true }, function () {
      this.getLubePurchaseList();
    });
  };

  render() {
    const { saleList } = this.state;
    return (
      <section className="widget full-width list-table">
        <header className="list-header">
          <h3>Purchase List</h3>
        </header>
        <main>
          <div className="table-filters">
            <div className="filter-wrapper">
              <div className="filter-group with-icon">
                <label>Results to display (Total: {this.state.count})</label>
                <form onSubmit={this.handleQuickSearch}>
                  <input type="text" name="limit" ref={this.limitInput} />
                  <button
                    type="submit"
                    className="search-icon"
                    onClick={this.handleQuickSearch}
                  />
                </form>
              </div>
              <div className="filter-group with-icon">
                <label>Invoice number:</label>
                <form onSubmit={this.handleQuickSearch}>
                  <input
                    type="text"
                    value={this.state.invoice_id}
                    onChange={this.handleBillChange}
                  />
                  <button
                    type="submit"
                    className="search-icon"
                    onClick={this.handleQuickSearch}
                  />
                </form>
              </div>
              <div className="filter-group">
                <label>From date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.start_date}
                  name="start_date"
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="filter-group">
                <label>To date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.end_date}
                  min={this.state.start_date}
                  onChange={this.handleDateChange}
                  name="end_date"
                />
              </div>
              <div className="filter-group">
                <label>Vendor:</label>
                <VendorList
                  value={this.state.vendor_id}
                  onChange={this.handleVendorChange}
                />
              </div>
              <div className="filter-group">
                <label>Product:</label>
                <ProductList
                  handleDropdownChange={this.handleProductChange}
                  value={this.state.product_id}
                  isShowDeleted={true}
                />
              </div>
              <div className="filter-group reset-filter">
                <button onClick={this.resetFilter}>Reset filters</button>
              </div>
              <div className="filter-group">
                  <button
                    className="submit"
                    type="submit"
                    onClick={this.submitForm}
                  >
                    Apply filter
                  </button>
                </div>
            </div>
          </div>
          <div className="table-wrapper">
            <div className="mini-summary">
              <ul>
                <li>
                  <span>Total sale amount: </span>
                  <span>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(
                      saleList.reduce(
                        (a, b) => a + (b['purchased_after_tax'] || 0),
                        0,
                      ) || 0,
                    )}
                  </span>
                </li>
              </ul>
            </div>
            {(() => {
              if (this.state.loading) {
                return <DropLoaderLarge />;
              } else {
                return (
                  <>
                    <TableGrid
                      col={col}
                      row={saleList}
                      offset={this.state.offset}
                      count={this.state.count}
                      limit={this.state.limit}
                      handlePagination={this.handlePagination}
                      onEdit={this.props.onEdit}
                      onDelete={this.confirmDelete}
                      exportOptions={{
                        fileName: `Lube Purchase List - ${this.state.start_date} to ${this.state.end_date}`,
                        reportName: 'Lube Purchase List',
                        reportDate: `Date - ${dayjs(
                          this.state.start_date,
                        ).format(
                          'D MMM YY',
                        )} to ${dayjs(this.state.end_date).format('D MMM YY')}`,
                        reportTotal: `Total amount -  ${saleList.reduce(
                          (a, b) => a + (b['purchased_after_tax'] || 0),
                          0,
                        ) || 0
                          }`,
                        reportQuantity: '',
                        reportSales: '',
                        reportTesting: '',
                        reportBowser: '',
                        reportCustomerName: '',
                        reportGst: '',
                        reportOpeningBalance: '',
                        reportClosingBalance: '',
                        reportSaleTotal: '',
                        reportTCSTotal: '',
                      }}
                      enabledActions={enabledActions}
                    />
                  </>
                );
              }
            })()}
          </div>
        </main>
      </section>
    );
  }
}

export default ListPurchase;
