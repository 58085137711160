import React, { Component } from 'react';
import dayjs from 'dayjs';

import { ProductList } from '@C/Lubes/_utils/';
import services from '@C/Reports/_utils/data';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { VendorList } from '@C/Utils/VendorList';

import { TableGrid } from '../../../TableGrid';

const col = [
  {
    name: 'Date',
    key: 'time_stamp',
    sortable: true,
    type: 'date',
  },
  {
    name: 'Invoice no.',
    key: 'invoice_number',
  },
  {
    name: 'Supplier Name',
    key: `supplier_first_name`,
  },
  {
    name: 'Product',
    key: 'lube_name',
  },
  {
    name: 'Quantity',
    key: 'no_of_units',
  },
  {
    name: 'Amount (Excl Tax)',
    key: 'purchased_amount',
    type: 'currency',
  },
  {
    name: 'GST',
    key: 'all',
    formatter(props) {
      return (
        <>
          {parseFloat(
            props.purchased_after_tax - props.purchased_amount,
          ).toFixed(2)}
        </>
      );
    },
    type: 'currency',
  },
  {
    name: 'TCS',
    key: 'tcs_amount',
    type: 'currency',
  },
  {
    name: 'Amount (Inc Tax)',
    key: 'purchased_after_tax',
    type: 'currency',
  },
  {
    name: 'Notes',
    key: 'notes',
  },
];

class LubesPurchase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slipList: [],
      product_id: '',
      vendor_id: '',
      start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      end_date: dayjs().format('YYYY-MM-DD'),
      loading: false,
      limit: 20,
      offset: 1,
      count: 0,
    };
    this.limitInput = React.createRef();
  }

  componentDidMount() {
    this.getLubesPurchase();
  }

  handleProductChange = (e) => {
    this.setState({ product_id: e?.value || '' });
  };

  handleVendorChange = (e) => {
    this.setState({ vendor_id: e?.value || '' });
  };

  getLubesPurchase = () => {
    services
      .getLubesPurchase(this.state)
      .then((response) => {
        this.setState({
          slipList: response.data.data.results,
          count: response.data.data.count,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handlePagination = (e) => {
    this.setState({ offset: e }, function () {
      this.getLubesPurchase();
    });
  };

  handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'start_date' && dayjs(value).isAfter(this.state.end_date)) {
      this.setState({ [name]: value, end_date: value });
    } else if (
      name === 'end_date' &&
      dayjs(value).isBefore(this.state.start_date)
    ) {
      this.setState({ [name]: value, start_date: value });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleQuickSearch = (e) => {
    e.preventDefault();
    if (
      this.limitInput.current.value === '' ||
      this.limitInput.current.value === '0'
    ) {
      return;
    }
    this.setState(
      { limit: this.limitInput.current.value, offset: 1 },
      function () {
        this.getLubesPurchase();
      },
    );
  };

  resetFilter = () => {
    this.limitInput.current.value = 20;
    this.setState(
      {
        start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        end_date: dayjs().format('YYYY-MM-DD'),
        loading: true,
        product_id: '',
        vendor_id: '',
        limit: 20,
        offset: 1,
      },
      function () {
        this.getLubesPurchase();
      },
    );
  };

  submitForm = (e) => {
    e.preventDefault();
    this.setState({ loading: true }, function () {
      this.getLubesPurchase();
    });
  };

  render() {
    const { slipList } = this.state;

    return (
      <section className="widget full-width list-table inverted">
        <header>
          <h3>Lube / Products Purchase Report</h3>
        </header>
        <main>
          <div className="table-filters">
            <div className="filter-wrapper">
              <div className="filter-group">
                <label>From date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.start_date}
                  name="start_date"
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="filter-group">
                <label>To date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.end_date}
                  min={this.state.start_date}
                  onChange={this.handleDateChange}
                  name="end_date"
                />
              </div>

              <div className="filter-group">
                <label>Select Product:</label>
                <ProductList
                  handleDropdownChange={this.handleProductChange}
                  value={this.state.product_id}
                  isShowDeleted={true}
                />
              </div>
              <div className="filter-group">
                <label>Select Vendor:</label>
                <VendorList
                  value={this.state.vendor_id}
                  onChange={this.handleVendorChange}
                />
              </div>
              <div className="filter-group with-icon">
                <label>
                  Results to display- {this.state.limit || 20} (Total:{' '}
                  {this.state.count})
                </label>
                <form onSubmit={this.handleQuickSearch}>
                  <input type="text" name="limit" ref={this.limitInput} />
                  <button
                    type="submit"
                    className="search-icon"
                    onClick={this.handleQuickSearch}
                  />
                </form>
              </div>
              <div className="filter-group reset-filter">
                <button onClick={this.resetFilter}>Reset filters</button>
              </div>
              <div className="filter-group">
                  <button
                    className="submit"
                    type="submit"
                    onClick={this.submitForm}
                  >
                    Apply filter
                  </button>
                </div>
            </div>
          </div>
          <div className="table-wrapper">
            <div className="mini-summary">
              <ul>
                <li>
                  <span> Total amount: </span>
                  <span>
                    {new Intl.NumberFormat('en-IN', {}).format(
                      slipList
                        .reduce(
                          (a, b) => a + (b['purchased_after_tax'] || 0),
                          0,
                        )
                        .toFixed(2) || 0,
                    )}
                  </span>
                </li>
              </ul>
            </div>
            {(() => {
              if (this.state.loading) {
                return <DropLoaderLarge />;
              } else {
                return (
                  <TableGrid
                    col={col}
                    row={slipList}
                    offset={this.state.offset}
                    count={this.state.count}
                    limit={this.state.limit}
                    handlePagination={this.handlePagination}
                    hideActions={true}
                    exportOptions={{
                      fileName: `Lubes Purchase - ${this.state.start_date} to ${this.state.end_date}.pdf`,
                      reportName: 'Lubes Purchase Report',
                      reportDate: `Date - ${dayjs(this.state.start_date).format(
                        'D MMM YY',
                      )} to ${dayjs(this.state.end_date).format('D MMM YY')}`,
                      reportTotal: `Total amount -  ${
                        slipList
                          .reduce(
                            (a, b) => a + (b['purchased_after_tax'] || 0),
                            0,
                          )
                          .toFixed(2) || 0
                      }`,
                      reportQuantity: '',
                      reportSales: '',
                      reportTesting: '',
                      reportBowser: '',
                      reportCustomerName: '',
                      reportGst: '',
                      reportOpeningBalance: '',
                      reportClosingBalance: '',
                      reportSaleTotal: '',
                      reportTCSTotal: '',
                    }}
                  />
                );
              }
            })()}
          </div>
        </main>
      </section>
    );
  }
}

export { LubesPurchase };
