import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import services from '@C/Admin/Users/_utils/data';
import { TableGrid } from '@C/TableGrid';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { authHeader } from '@H';

const col = [
  {
    name: 'Bunk register date',
    key: 'created_on',
    sortable: true,
    type: 'date',
  },
  {
    name: 'Bunk name',
    key: 'all',
    formatter(props) {
      return (
        <>
          <Link to={`/admin/users/bunk/${props.account_id}`}>
            {' '}
            {props.name}{' '}
          </Link>
        </>
      );
    },
  },
  {
    name: 'OMC',
    key: 'omc_name',
  },
  {
    name: 'Contact',
    key: 'contact',
  },
  {
    name: 'City',
    key: 'city',
  },
  {
    name: 'State',
    key: 'state',
  },
  {
    name: 'Country',
    key: 'country',
  },

  {
    name: 'Expiry date',
    key: '',
  },
  {
    name: 'Executive name',
    key: '',
  },
  {
    name: 'Current status',
    key: '',
  },
];

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dealerList: [],
      start_date: this.props.cod
        ? this.props.date
        : dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      end_date: this.props.cod ? this.props.date : dayjs().format('YYYY-MM-DD'),
      loading: true,
      limit: 20,
      offset: 1,
      count: 0,
    };
    this.limitInput = React.createRef();
  }

  componentDidMount() {
    this.getDealersList();
  }

  getDealersList = () => {
    services
      .getDealersList(this.state)
      .then((response) => {
        this.setState({
          dealerList: response.data.data.results,
          loading: false,
          count: response.data.data.count,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handlePagination = (e) => {
    this.setState({ offset: e, loading: true }, function () {
      this.getDealersList();
    });
  };

  confirmDelete = (item) => {
    confirmAlert({
      title: 'Confirm dealer account deletion',
      message: 'Are you sure',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteItem(item),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  deleteItem = (item) => {
    const headers = authHeader(1);
    axios
      .delete(
        `${process.env.API_URL}/users/delete-dealer/?account_id=${item.uuid}`,
        {
          headers,
        },
      )
      .then(() => {
        toast.success('Dealer account deleted successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        this.getDealersList();
      });
  };

  handleDateChange = (e) => {
    this.setState(
      { [e.target.name]: e.target.value, loading: true },
      function () {
        this.getDealersList();
      },
    );
  };

  handleQuickSearch = (e) => {
    e.preventDefault();
    if (
      this.limitInput.current.value === '' ||
      this.limitInput.current.value === '0'
    ) {
      return;
    }
    this.setState(
      { limit: this.limitInput.current.value, offset: 1 },
      function () {
        this.getDealersList();
      },
    );
  };

  resetFilter = () => {
    this.setState(
      {
        start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        end_date: dayjs().format('YYYY-MM-DD'),
        loading: true,
      },
      function () {
        this.getDealersList();
      },
    );
  };

  render() {
    const { dealerList } = this.state;
    return (
      <section
        className={`widget full-width list-table ${this.props.cod ? 'inverted' : ''}`}
      >
        <header>
          <h3> All Users List </h3>
        </header>
        <main>
          {!this.props.cod && (
            <div className="table-filters">
              <div className="filter-wrapper">
                <div className="filter-group with-icon">
                  <label>
                    Results to display- {this.state.limit || 20} (Total:{' '}
                    {this.state.count})
                  </label>
                  <form onSubmit={this.handleQuickSearch}>
                    <input type="text" name="limit" ref={this.limitInput} />
                    <button
                      type="submit"
                      className="search-icon"
                      onClick={this.handleQuickSearch}
                    />
                  </form>
                </div>
                <div className="filter-group">
                  <label>From date:</label>
                  <input
                    className="cstm-date-input"
                    type="date"
                    value={this.state.start_date}
                    name="start_date"
                    onChange={this.handleDateChange}
                  />
                </div>
                <div className="filter-group">
                  <label>To date:</label>
                  <input
                    className="cstm-date-input"
                    type="date"
                    value={this.state.end_date}
                    onChange={this.handleDateChange}
                    name="end_date"
                  />
                </div>
                <div className="filter-group">
                  <label> City </label>
                  <input
                    type="text"
                    value={this.state.city}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="filter-group">
                  <label> State </label>
                  <input
                    type="text"
                    value={this.state.state}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="filter-group">
                  <label> Country </label>
                  <input
                    type="text"
                    value={this.state.country}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="filter-group">
                  <label> Executive name </label>
                  <input
                    type="text"
                    value={this.state.executive_name}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="filter-group reset-filter">
                  <button onClick={this.resetFilter}>Reset filters</button>
                </div>
              </div>
            </div>
          )}

          <div className="table-wrapper">
            {(() => {
              if (this.state.loading) {
                return <DropLoaderLarge />;
              } else {
                return (
                  <>
                    <TableGrid
                      col={col}
                      row={dealerList}
                      offset={this.state.offset}
                      count={this.state.count}
                      limit={this.state.limit}
                      handlePagination={this.handlePagination}
                      onEdit={this.props.onEdit}
                      onDelete={this.confirmDelete}
                    />
                  </>
                );
              }
            })()}
          </div>
        </main>
      </section>
    );
  }
}

export { UserList };
