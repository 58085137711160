import React, { useEffect, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';

import services from '@C/Banking/_utils/data';
import { TableGrid } from '@C/TableGrid';
import { BankList } from '@C/Utils';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { authHeader } from '@H';

const col = [
  {
    name: 'Date',
    key: 'time_stamp',
    sortable: true,
    type: 'date',
  },
  {
    name: 'Debit bank',
    key: 'debit_tx',
    formatter: (props) => <>{props.bank.name}</>,
  },
  {
    name: 'Credit bank',
    key: 'credit_tx',
    formatter: (props) => <>{props.bank.name}</>,
  },
  {
    name: 'Amount',
    key: 'amount',
    type: 'currency',
  },
  {
    name: 'Notes',
    key: 'reference_number',
  },
];

const enabledActions = [
  {
    action: 'Delete',
    roles: ['Manager', 'Dealer'],
  },
];

const B2BtransferList = (props) => {
  const [transferList, setTransferList] = useState([]);
  const [filterData, setFilterData] = useState({
    offset: 1,
    count: 0,
    limit: 20,
    from_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    end_date: dayjs().format('YYYY-MM-DD'),
    credit_bank: '',
    debit_bank: '',
    refreshList: false,
  });
  const [loading, setLoading] = useState(false);

  const getTransferList = async () => {
    setLoading(true);
    const { data } = await services.getB2BTransferList(filterData);
    if (data) {
      setTransferList(data.data.results);
      setFilterData({ ...filterData, count: data.data.count });
    }
    setLoading(false);
  };

  useEffect(() => {
    getTransferList();
  }, [filterData.refreshList]);

  const handleDateChange = (e) => {
    const { name, value } = e.target;

    setFilterData((prevFilterData) => {
      if (
        name === 'from_date' &&
        dayjs(value).isAfter(prevFilterData.end_date)
      ) {
        return {
          ...prevFilterData,
          [name]: value,
          end_date: value,
        };
      } else if (
        name === 'end_date' &&
        dayjs(value).isBefore(prevFilterData.from_date)
      ) {
        return {
          ...prevFilterData,
          [name]: value,
          from_date: value,
        };
      } else {
        return {
          ...prevFilterData,
          [name]: value,
        };
      }
    });
  };

  const handleDebitBankChange = (e) => {
    setFilterData({ ...filterData, debit_bank: e?.value });
  };

  const handleCreditBankChange = (e) => {
    setFilterData({ ...filterData, credit_bank: e?.value });
  };

  const handlePagination = (e) => {
    setFilterData({
      ...filterData,
      offset: e,
      refreshList: !filterData.refreshList,
    });
  };
  const confirmDelete = (item) => {
    confirmAlert({
      title: 'Confirm transaction deletion',
      message: 'Are you sure',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteItem(item),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  const deleteItem = (item) => {
    const headers = authHeader(1);
    axios
      .delete(
        `${process.env.API_URL}/payment/bank-to-bank-transfer?transaction_id=${item.uuid}`,
        {
          headers,
        },
      )
      .then(() => {
        toast.success('Transaction deleted successfully');
        setFilterData({ ...filterData, refreshList: !filterData.refreshList });
      });
  };

  const resetFilter = () => {
    setFilterData({
      offset: 1,
      count: 0,
      limit: 20,
      from_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      end_date: dayjs().format('YYYY-MM-DD'),
      credit_bank: '',
      debit_bank: '',
      refreshList: !filterData.refreshList,
    });
  };

  const submitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    setFilterData({ ...filterData, refreshList: !filterData.refreshList });
  };

  return (
    <>
      <section className={`widget full-width list-table`}>
        <header>
          <h3>B2B Transfer List</h3>
        </header>
        <main>
          <div className="table-filters">
            <div className="filter-wrapper">
              <div className="filter-group">
                <label>From date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={filterData.from_date}
                  name="from_date"
                  onChange={handleDateChange}
                />
              </div>
              <div className="filter-group">
                <label>To date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={filterData.end_date}
                  onChange={handleDateChange}
                  name="end_date"
                />
              </div>
              <div className="filter-group">
                <label>Debited bank:</label>
                <BankList
                  value={filterData.debit_bank}
                  handleBankChange={handleDebitBankChange}
                />
              </div>
              <div className="filter-group">
                <label>Credited bank:</label>
                <BankList
                  value={filterData.credit_bank}
                  handleBankChange={handleCreditBankChange}
                />
              </div>
              <div className="filter-group reset-filter">
                <button onClick={resetFilter}>Reset filters</button>
              </div>
              <div className="filter-group">
                <button className="submit" type="submit" onClick={submitForm}>
                  Apply filter
                </button>
              </div>
            </div>
          </div>

          <div className="table-wrapper">
            {(() => {
              if (loading) {
                return <DropLoaderLarge />;
              } else {
                return (
                  <>
                    <TableGrid
                      col={col}
                      row={transferList}
                      offset={filterData.offset}
                      count={filterData.count}
                      limit={filterData.limit}
                      handlePagination={handlePagination}
                      onDelete={confirmDelete}
                      enabledActions={enabledActions}
                    />
                  </>
                );
              }
            })()}
          </div>
        </main>
      </section>
    </>
  );
};

export { B2BtransferList };
