import React, { Component } from 'react';
import dayjs from 'dayjs';

import { ProductList, VehicleList } from '@C/CreditCustomers/_utils/';
import services from '@C/CreditCustomers/_utils/data';
import { TableGrid } from '@C/TableGrid';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { authService } from '@S';

import './styles.scoped.scss';
const col = [
  {
    name: 'Date',
    key: 'time_stamp',
    sortable: true,
    type: 'date',
  },
  {
    name: 'Slip #',
    key: 'slip_no',
  },
  {
    name: 'Vehicle',
    key: 'vehicle.vehicle_no',
  },
  {
    name: 'Product',
    key: 'product',
    formatter(props) {
      return <>{props?.name || 'Cash Given'}</>;
    },
  },
  {
    name: 'Rate',
    key: 'product',
    formatter(props) {
      return <>{props?.price || '--'}</>;
    },
  },
  {
    name: 'Qty',
    key: 'quantity',
  },
  {
    name: 'Discount',
    key: 'discount',
    type: 'currency',
  },
  {
    name: 'Amount (Excl Tax)',
    key: 'after_discount',
    type: 'currency',
  },
  {
    name: 'Cash given',
    key: 'cash_given',
    type: 'currency',
  },
  {
    name: 'Amount (Inc Tax)',
    key: 'after_tax',
    type: 'currency',
  },
  {
    name: 'Cashier',
    key: 'cashier',
    formatter(props) {
      return (
        <>
          {props.first_name} {props.last_name}
        </>
      );
    },
  },
];

class CreditSale extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creditSaleList: [],
      customer_id: authService.currentUserId,
      offset: 1,
      count: 0,
      limit: 20,
      start_date: this.props.cod
        ? this.props.date
        : dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      end_date: this.props.cod ? this.props.date : dayjs().format('YYYY-MM-DD'),
      product_id: '',
      vehicle_number: '',
      loading: true,
    };
    this.limitInput = React.createRef();
  }

  componentDidMount() {
    this.getCreditCustomerSlips();
  }

  getCreditCustomerSlips = () => {
    services
      .getCreditCustomerSlips(this.state)
      .then((response) => {
        this.setState({
          creditSaleList: response.data.data.results,
          count: response.data.data.count,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handlePagination = (e) => {
    this.setState({ offset: e, loading: true }, function () {
      this.getCreditCustomerSlips();
    });
  };
  handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'start_date' && dayjs(value).isAfter(this.state.end_date)) {
      this.setState({ [name]: value, end_date: value, loading: true }, () =>
        this.getCreditCustomerSlips(),
      );
    } else if (
      name === 'end_date' &&
      dayjs(value).isBefore(this.state.start_date)
    ) {
      this.setState({ [name]: value, start_date: value, loading: true }, () =>
        this.getCreditCustomerSlips(),
      );
    } else {
      this.setState({ [name]: value, loading: true }, () =>
        this.getCreditCustomerSlips(),
      );
    }
  };

  handleProductChange = (e) => {
    this.setState({ product_id: e?.value || '', loading: true }, function () {
      this.getCreditCustomerSlips();
    });
  };

  handleVehicleChange = (e) => {
    this.setState(
      { vehicle_number: e?.value || '', loading: true },
      function () {
        this.getCreditCustomerSlips();
      },
    );
  };

  handleQuickSearch = (e) => {
    e.preventDefault();
    if (
      this.limitInput.current.value === '' ||
      this.limitInput.current.value === '0'
    ) {
      return;
    }
    this.setState(
      { limit: this.limitInput.current.value, offset: 1 },
      function () {
        this.getCreditCustomerSlips();
      },
    );
  };

  resetFilter = () => {
    this.setState(
      {
        start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        end_date: dayjs().format('YYYY-MM-DD'),
        product_id: '',
        vehicle_number: '',
        loading: true,
      },
      function () {
        this.getCreditCustomerSlips();
      },
    );
  };

  render() {
    const { creditSaleList } = this.state;
    return (
      <>
        <section className="widget full-width list-table">
          <header>
            <h3> Credit sale list </h3>
          </header>
          <main>
            <div className="table-filters">
              <div className="filter-wrapper">
                <div className="filter-group">
                  <label>From date:</label>
                  <input
                    className="cstm-date-input"
                    type="date"
                    value={this.state.start_date}
                    name="start_date"
                    onChange={this.handleDateChange}
                  />
                </div>
                <div className="filter-group">
                  <label>To date:</label>
                  <input
                    className="cstm-date-input"
                    type="date"
                    value={this.state.end_date}
                    onChange={this.handleDateChange}
                    name="end_date"
                  />
                </div>
                <div className="filter-group">
                  <label>Product:</label>
                  <ProductList
                    onProductChange={this.handleProductChange}
                    value={this.state.product_id}
                    options={this.props.products}
                  />
                </div>
                <div className="filter-group">
                  <label> Vehicle:</label>
                  <VehicleList
                    handleDropdownChange={this.handleVehicleChange}
                    value={this.state.vehicle_number}
                    selectedCustomerId={this.state.customer_id}
                  />
                </div>

                <div className="filter-group with-icon">
                  <label>
                    Results to display- {this.state.limit || 20} (Total:{' '}
                    {this.state.count})
                  </label>
                  <form onSubmit={this.handleQuickSearch}>
                    <input type="text" name="limit" ref={this.limitInput} />
                    <button
                      type="submit"
                      className="search-icon"
                      onClick={this.handleQuickSearch}
                    />
                  </form>
                </div>
                <div className="filter-group reset-filter">
                  <button onClick={this.resetFilter}>Reset filters</button>
                </div>
              </div>
            </div>
            <div className="table-wrapper">
              <div className="mini-summary">
                <ul>
                  <li>
                    <span>Total sale amount: </span>
                    <span>
                      {new Intl.NumberFormat('en-IN', {
                        style: 'currency',
                        currency: 'INR',
                      }).format(
                        creditSaleList.reduce(
                          (a, b) => a + (b['after_tax'] || 0),
                          0,
                        ) || 0,
                      )}
                    </span>
                  </li>
                  <li>
                    <span>Total Quantity: </span>
                    <span>
                      {new Intl.NumberFormat('en-IN', {}).format(
                        creditSaleList.reduce(
                          (a, b) => a + (b['quantity'] || 0),
                          0,
                        ) || 0,
                      )}
                    </span>
                  </li>
                </ul>
              </div>
              {(() => {
                if (this.state.loading) {
                  return <DropLoaderLarge />;
                } else {
                  return (
                    <TableGrid
                      col={col}
                      row={creditSaleList}
                      offset={this.state.offset}
                      hideActions={true}
                      count={this.state.count}
                      limit={this.state.limit}
                      handlePagination={this.handlePagination}
                      invoice="credit"
                    />
                  );
                }
              })()}
            </div>
          </main>
        </section>
      </>
    );
  }
}

export { CreditSale };
