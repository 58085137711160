import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';

import services from '@C/StaffManagement/_utils/data';
import { TableGrid } from '@C/TableGrid';
import { PaymentMethodInfo, StaffList } from '@C/Utils';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { authHeader } from '@H';

const col = [
  {
    name: 'Date',
    key: 'time_stamp',
    sortable: true,
    type: 'date',
  },
  {
    name: 'Employee',
    key: 'staff',
    formatter(props) {
      return (
        <>
          {props.first_name} {props.last_name}
        </>
      );
    },
  },
  {
    name: 'Returned amount',
    key: 'amount',
    type: 'currency',
  },
  {
    name: 'Advance pending',
    key: 'pending_amount',
    type: 'currency',
  },
  {
    name: 'Amount returned to',
    key: 'handover_to',
    formatter(props) {
      return (
        <>
          {props.first_name} {props.last_name}
        </>
      );
    },
  },
  {
    name: 'Payment mode',
    key: 'all',
    formatter(props) {
      return (
        <>
          <PaymentMethodInfo
            source={props.payment?.source || 'CASH'}
            method={props.payment?.payment_method || 'CASH'}
            bank={props.bank}
          />
        </>
      );
    },
  },
  {
    name: 'Notes',
    key: 'notes',
  },
];

const enabledActions = [
  {
    action: 'Delete',
    roles: ['Manager', 'Dealer'],
  },
];

export default class AdvanceReturnList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      advanceReturnList: [],
      start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      end_date: dayjs().format('YYYY-MM-DD'),
      limit: 20,
      offset: 1,
      count: 0,
      staff_id: '',
      given_by: '',
      loading: true,
    };
    this.limitInput = React.createRef();
  }

  componentDidMount() {
    this.getAdvanceRepaymentList();
  }

  getAdvanceRepaymentList = () => {
    services
      .getAdvanceRepaymentList(this.state)
      .then((res) => {
        this.setState({
          advanceReturnList: res.data.data.results,
          loading: false,
          count: res.data.data.count,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handlePagination = (e) => {
    this.setState({ offset: e, loading: true }, function () {
      this.getAdvanceRepaymentList();
    });
  };

  handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'start_date' && dayjs(value).isAfter(this.state.end_date)) {
      this.setState({ [name]: value, end_date: value }());
    } else if (
      name === 'end_date' &&
      dayjs(value).isBefore(this.state.start_date)
    ) {
      this.setState({ [name]: value, start_date: value });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleStaffChange = (e) => {
    this.setState({ staff_id: e.value });
  };

  handleProviderChange = (e) => {
    this.setState({ given_by: e.value });
  };

  handleQuickSearch = (e) => {
    e.preventDefault();
    if (
      this.limitInput.current.value === '' ||
      this.limitInput.current.value === '0'
    ) {
      return;
    }
    this.setState(
      { limit: this.limitInput.current.value, offset: 1 },
      function () {
        this.getAdvanceRepaymentList();
      },
    );
  };
  handleLimitChange = (e) => {
    this.setState({ limit: e.target.value, offset: 1 });
  };

  resetFilter = () => {
    this.setState(
      {
        start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        end_date: dayjs().format('YYYY-MM-DD'),
        limit: 20,
        offset: 1,
        count: 0,
        staff_id: '',
        given_by: '',
        loading: true,
      },
      function () {
        this.getAdvanceRepaymentList();
      },
    );
  };

  confirmDelete = (item) => {
    confirmAlert({
      title: 'Confirm transaction deletion',
      message: 'Are you sure',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteItem(item),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  deleteItem = (item) => {
    const headers = authHeader(1);
    axios
      .delete(
        `${process.env.API_URL}/staff-management/bunk/advance-repayment/?uuid=${item.uuid}`,
        {
          headers,
        },
      )
      .then(() => {
        toast.success('Transaction deleted successfully');
        this.getAdvanceRepaymentList();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };
  submitForm = (e) => {
    e.preventDefault();
    this.setState({ loading: true }, function () {
      this.getAdvanceRepaymentList();
    });
  };

  render() {
    const { advanceReturnList } = this.state;

    return (
      <>
        <section className="widget full-width list-table">
          <header>
            <h3>Advance return list</h3>
          </header>
          <main>
            <div className="table-filters">
              <div className="filter-wrapper">
                <div className="filter-group with-icon">
                  <label>
                    Results to display- {this.state.limit || 20} (Total:{' '}
                    {this.state.count})
                  </label>
                  <form onSubmit={this.handleQuickSearch}>
                    <input type="text" name="limit" ref={this.limitInput} />
                    <button
                      type="submit"
                      className="search-icon"
                      onClick={this.handleQuickSearch}
                    />
                  </form>
                </div>
                <div className="filter-group">
                  <label>From date:</label>
                  <input
                    className="cstm-date-input"
                    type="date"
                    value={this.state.start_date}
                    name="start_date"
                    onChange={this.handleDateChange}
                  />
                </div>
                <div className="filter-group">
                  <label>To date:</label>
                  <input
                    className="cstm-date-input"
                    type="date"
                    value={this.state.end_date}
                    onChange={this.handleDateChange}
                    name="end_date"
                  />
                </div>
                <div className="filter-group">
                  <label>Staff:</label>
                  <StaffList
                    value={this.state.staff_id}
                    onChange={this.handleStaffChange}
                  />
                </div>
                <div className="filter-group">
                  <label>Given by:</label>
                  <StaffList
                    value={this.state.given_by}
                    onChange={this.handleProviderChange}
                  />
                </div>
                <div className="filter-group reset-filter">
                  <button onClick={this.resetFilter}>Reset filters</button>
                </div>
                <div className="filter-group">
                  <button
                    className="submit"
                    type="submit"
                    onClick={this.submitForm}
                  >
                    Apply filter
                  </button>
                </div>
              </div>
            </div>
            <div className="table-wrapper">
              {(() => {
                if (this.state.loading) {
                  return <DropLoaderLarge />;
                } else {
                  return (
                    <>
                      <TableGrid
                        col={col}
                        row={advanceReturnList}
                        limit={this.state.limit}
                        count={this.state.count}
                        onEdit={this.props.onEdit}
                        onDelete={this.confirmDelete}
                        offset={this.state.offset}
                        handlePagination={this.handlePagination}
                        enabledActions={enabledActions}
                      />
                    </>
                  );
                }
              })()}
            </div>
          </main>
        </section>
      </>
    );
  }
}
