import React, { Component } from 'react';
import dayjs from 'dayjs';
import SimpleReactValidator from 'simple-react-validator';

import { BackupList } from './backupList';

class DataBackUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file_list: [
        {
          month: dayjs().format('YYYY-MM'),
          file_description: '',
          start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
          end_date: dayjs().format('YYYY-MM-DD'),
        },
      ],
    };
    this.myRef = React.createRef();
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
  }

  addRow = () => {
    this.setState((prevState) => ({
      file_list: [
        ...prevState.file_list,
        {
          file_description: '',
        },
      ],
    }));
  };

  handleDateChange = (e) => {
    const start = `${e.target.value}-01`;
    const end = `${e.target.value}-31`;
    this.setState({
      month: e.target.value,
      start_date: start,
      end_date: end,
    });
  };

  render() {
    const fileList = this.state.file_list;
    return (
      <>
        <section className="widget full-width form-table">
          <main>
            <div className="form-header">
              <div className="input-wrapper with-label">
                <label> Select year </label>
                <input
                  className="cstm-date-input"
                  type="month"
                  value={this.state.month}
                  max={dayjs().format('YYYY-MM')}
                  name="start_date"
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="actions">
                <button className="add-row" onClick={() => this.addRow()}>
                  Add row
                </button>
              </div>
            </div>
            <div className="form-content">
              <table>
                <thead>
                  <tr>
                    <th>File description</th>
                    <th>file location</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {fileList.map((item, i) => (
                    <tr key={i}>
                      <td>
                        <div className="input-wrapper">
                          <input
                            type="text"
                            value={item.file_description}
                            placeholder="file description"
                            name="file_description"
                            onChange={this.onInputChange}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper">
                          <input type="file" id="myFile" name="filename" />
                        </div>
                      </td>
                      <td>
                        {' '}
                        <div className="input-wrapper _actions">
                          {i > 0 ? (
                            <button
                              className="delete-row icon-only"
                              onClick={() => this.deleteRow(i)}
                            >
                              Delete row
                            </button>
                          ) : (
                            ''
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="form-actions">
              <button
                className="submit"
                type="submit"
                disabled={this.state.isSubmitting}
                onClick={this.submitForm}
              >
                Upload
              </button>
            </div>
          </main>
        </section>
        <BackupList />
      </>
    );
  }
}
export { DataBackUp };
