import axios from 'axios';

import { authHeader } from '@H';
import { authService } from '@S';

export default {
  getStaffList: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/staff/associate-staff/`,
    }),
  getTankList: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/associate-tanks/`,
    }),
  getCreditLedger: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/credit-customer/bunk/customer-ledger/?limit=${data.limit}&offset=${data.offset}&staff_id=${data.staff_id}&from_date=${data.start_date}&to_date=${data.end_date}&customer_id=${data.customer_id}&vehicle_id=${data.vehicle_number}&product_id=${data.product_id}&slip_number=${data.slip_no}&shift_id=${data.shift_id}`,
    }),
  getProductList: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/products/list/`,
    }),

  getCreditSlips: (data) => {
    return axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/credit-customer/bunk/credit-slip/?staff_id=${data.staff_id}&limit=${data.limit}&offset=${data.offset}&start_date=${data.start_date}&end_date=${data.end_date}&credit_customer_id=${data.credit_customer_id}&vehicle_number=${data.vehicle_number}&product_id=${data.product_id}&slip_number=${data.slip_no}&shift_id=${data.shift_id}`,
    });
  },

  getCreditSlipsBowser: (data) => {
    return axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/credit-customer/bunk/credit-slip/?staff_id=${data.staff_id}&limit=${data.limit}&offset=${data.offset}&start_date=${data.start_date}&end_date=${data.end_date}&credit_customer_id=${data.credit_customer_id}&vehicle_number=${data.vehicle_number}&product_id=${data.product_id}&slip_number=${data.slip_no}&shift_id=${data.shift_id}&tank_id=${data.tank_id}`,
    });
  },

  getBowserList: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/associate-tanks/?is_bowser=1`,
    }),

  getCreditCustomerList: () => {
    return axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/credit-customer/bunk/customer/`,
    });
  },
  getMonthlyReport: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/dip/monthly-report/?start_date=${data.start_date}&end_date=${data.end_date}&limit=${data.limit}&offset=${data.offset}&tank_id=${data.tank_id}`,
    }),
  getBankLedger: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/bank-ledger/?start_date=${data.start_date}&end_date=${data.end_date}&limit=${data.limit}&offset=${data.offset}&bank_id=${data.bank_id}`,
    }),

  getPOSLedger: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/bank-ledger/?start_date=${data.start_date}&end_date=${data.end_date}&limit=${data.limit}&offset=${data.offset}&source=SWIPE&bank_id=${data.bank_id}`,
    }),

  getWalletLedger: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/bank-ledger/?start_date=${data.start_date}&end_date=${data.end_date}&limit=${data.limit}&offset=${data.offset}&source=WALLET&bank_id=${data.bank_id}`,
    }),

  getLubesPurchase: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/lubes/bunk/vendor/purchase/?start_date=${data.start_date}&end_date=${data.end_date}&limit=${data.limit}&offset=${data.offset}&product_id=${data.product_id}&vendor_id=${data.vendor_id}`,
    }),
  getIncomeReport: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/transactions/?start_date=${data.start_date}&end_date=${data.end_date}&limit=${data.limit}&offset=${data.offset}&product_id=${data.product_id}&staff_id=${data.staff_id}&product_type=Other`,
    }),

  getTemporaryCreditLedgerReport: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/temp-credit/report/?limit=${data.limit}&offset=${data.offset}&start_date=${data.start_date}&end_date=${data.end_date}&staff_id=${data.staff_id}`,
    }),
  getExpenseReport: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/transactions/?start_date=${data.start_date}&end_date=${data.end_date}&limit=${data.limit}&offset=${data.offset}&product_id=${data.product_id}&staff_id=${data.staff_id}&product_type=Expenses`,
    }),
  getLubesSalesReport: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/transactions/?product_type=Lubes&limit=${data.limit}&offset=${data.offset}&start_date=${data.start_date}&end_date=${data.end_date}&staff_id=${data.staff_id}&product_id=${data.product_uuid}&lube_category=${data.lube_category}`,
    }),

  getLubesPriceReport: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/lubes/bunk/lube-price/?limit=${data.limit}&offset=${data.offset}&start_date=${data.start_date}&end_date=${data.end_date}&lube_id=${data.product_uuid}&category_id=${data.lube_category}`,
    }),

  getFuelPurchase: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/fuel-purchase/?limit=${data.limit}&offset=${data.offset}&start_date=${data.start_date}&end_date=${data.end_date}&product_id=${data.product_id}&suppiler_id=${data.vendor_id}`,
    }),
  getFuelPrice: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/fuel-prices/?change_history=1&limit=${data.limit}&offset=${data.offset}&date=${data.date}&product_id=${data.product_id}`,
    }),

  getPLTAReport: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/trading/report/?start_date=${data.start_date}&end_date=${data.end_date}`,
    }),
  getPLReport: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/profit-loss/report?start_date=${data.start_date}&end_date=${data.end_date}`,
    }),
  getCashierShortageReport: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/shortage-report/?limit=${data.limit}&offset=${data.offset}&start_date=${data.start_date}&end_date=${data.end_date}&cashier_id=${data.staff_id}`,
    }),
  getCashbookReport: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/cashbook-report/?limit=${data.limit}&offset=${data.offset}&start_date=${data.start_date}&end_date=${data.end_date}`,
    }),
  getCashbookShiftwiseReport: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/cashbook-report/?is_shift=1&limit=${data.limit}&offset=${data.offset}&start_date=${data.start_date}&end_date=${data.end_date}`,
    }),
  getBusinessSummaryReport: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/sales-report/?start_date=${data.start_date}&end_date=${data.end_date}&staff_id=${data.staff_id}&shift_id=${data.shift_id}`,
    }),
  getDenominationReport: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/cash-denomination-report/?limit=${data.limit}&offset=${data.offset}&date=${data.date}&cashier_duty_id=${data.cashier_duty_id}&shift_id=${data.shift_id}&cash_code=${data.sortOrder}`,
    }),
  getCumulativeReport: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/credit-customer/bunk/cumulative/credit-report/?limit=${data.limit}&offset=${data.offset}&from_date=${data.start_date}&to_date=${data.end_date}&customer_id=${data.customer_id}`,
    }),
  getProfitMargin: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/fuel-profit-margin-report/?limit=${data.limit}&offset=${data.offset}&start_date=${data.start_date}&end_date=${data.end_date}&product_id=${data.product_id}`,
    }),
  delete: (url) => {
    return axios({
      method: 'DELETE',
      // 'params': { ID: 12345 },
      headers: authHeader(1),
      url: url,
    });
  },
  get: (url) => {
    return axios({
      method: 'GET',
      headers: authHeader(1),
      url: url,
    });
  },
  post: (url, body) => {
    const headers = authHeader(1);
    return axios.post(url, body, { headers });
  },
  getTaxDetails: () =>
    axios({
      method: 'GET',
      headers: authHeader(),
      url: `${process.env.API_URL}/utils/tax/${authService.currentBunkCountryUUID}/?by_group=1`,
    }),
  getFuelSales: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/fuel-sale-report/?limit=${data.limit}&offset=${data.offset}&start_date=${data.start_date}&end_date=${data.end_date}&product_id=${data.product_id}`,
    }),
  getMappedWallets: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/mapped-wallets/list/?is_all=1`,
    }),
  getMappedPOS: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/map-swipe-machines/list/?is_all=1`,
    }),
  getMappedBanks: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/mapped-banks/list/?is_all=1`,
    }),
  getVendorPayment: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/lubes/bunk/vendor/payments/?limit=${data.limit}&offset=${data.offset}&start_date=${data.start_date}&end_date=${data.end_date}&staff_id=${data.staff_id}&vendor_id=${data.vendor_id}`,
    }),
  getBalanceSheet: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/balance-sheet/report/?start_date=${data.start_date}&end_date=${data.end_date}`,
    }),
  getGSTIncome: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/gst-income-report/?start_date=${data.start_date}&end_date=${data.end_date}&queryParam=${data.queryParam}&limit=${data.limit}&offset=${data.offset}`,
    }),
  getGSTExpense: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/gst-expense-report/?start_date=${data.start_date}&end_date=${data.end_date}&queryParam=${data.queryParam}&limit=${data.limit}&offset=${data.offset}`,
    }),
};
