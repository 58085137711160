import React, { Component } from 'react';
import dayjs from 'dayjs';

import services from '@C/Reports/_utils/data';
import { TableGrid } from '@C/TableGrid';
import { FuelList, VendorList } from '@C/Utils';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';

const col = [
  {
    name: 'Date',
    key: 'time_stamp',
    sortable: true,
    type: 'date',
  },
  {
    name: 'Invoice number',
    key: 'invoice_number',
  },
  {
    name: 'Supplier Name',
    key: 'all',
    formatter(props) {
      return (
        <>
          {props.vendor_first_name} {props.vendor_last_name}
        </>
      );
    },
  },
  {
    name: 'Product',
    key: 'product',
  },
  {
    name: 'Quantity',
    key: 'total_quantity',
  },
  {
    name: 'Amount',
    key: 'amount',
    type: 'currency',
  },
  {
    name: 'Discount',
    key: 'discount',
    type: 'currency',
  },
  {
    name: 'VAT',
    key: 'vat',
    type: 'currency',
  },
  {
    name: 'Additional VAT',
    key: 'additional_vat',
    type: 'currency',
  },
  {
    name: 'Reverse dealer commission',
    key: 'reverse_dealer_commission',
    type: 'currency',
  },
  {
    name: 'Recovery for LFR/SSLF',
    key: 'recovery_for_lfr_sslf',
    type: 'currency',
  },
  {
    name: 'TCS Amount',
    key: 'tcs_amount',
    type: 'currency',
  },
  {
    name: 'Total amount',
    key: 'base_amount',
    type: 'currency',
  },
  {
    name: 'Notes',
    key: 'notes',
  },
];
class FuelPurchaseReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      purchaseList: [],
      product_id: '',
      vendor_id: '',
      start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      end_date: dayjs().format('YYYY-MM-DD'),
      loading: false,
      limit: 20,
      offset: 1,
      count: 0,
    };
    this.limitInput = React.createRef();
  }

  componentDidMount() {
    this.getFuelPurchase();
  }

  handleProductChange = (e) => {
    this.setState({ product_id: e.value });
  };

  handleVendorChange = (e) => {
    this.setState({ vendor_id: e?.value || '' }, function () {
      this.getFuelPurchase();
    });
  };

  getFuelPurchase = () => {
    services
      .getFuelPurchase(this.state)
      .then((response) => {
        this.setState({
          purchaseList: response.data.data.results,
          count: response.data.data.count,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handlePagination = (e) => {
    this.setState({ offset: e }, function () {
      this.getFuelPurchase();
    });
  };

  handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'start_date' && dayjs(value).isAfter(this.state.end_date)) {
      this.setState({ [name]: value, end_date: value });
    } else if (
      name === 'end_date' &&
      dayjs(value).isBefore(this.state.start_date)
    ) {
      this.setState({ [name]: value, start_date: value });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleQuickSearch = (e) => {
    e.preventDefault();
    if (
      this.limitInput.current.value === '' ||
      this.limitInput.current.value === '0'
    ) {
      return;
    }
    this.setState(
      { limit: this.limitInput.current.value, offset: 1 },
      function () {
        this.getFuelPurchase();
      },
    );
  };

  resetFilter = () => {
    this.limitInput.current.value = 20;
    this.setState(
      {
        start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        end_date: dayjs().format('YYYY-MM-DD'),
        loading: true,
        product_id: '',
        vendor_id: '',
        limit: 20,
        offset: 1,
      },
      function () {
        this.getFuelPurchase();
      },
    );
  };
  submitForm = (e) => {
    e.preventDefault();
    this.setState({ loading: true }, function () {
      this.getFuelPurchase();
    });
  };
  render() {
    const { purchaseList } = this.state;

    return (
      <section className="widget full-width list-table inverted">
        <header>
          <h3>Fuel Purchase Report</h3>
        </header>
        <main>
          <div className="table-filters">
            <div className="filter-wrapper">
              <div className="filter-group">
                <label>From date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.start_date}
                  name="start_date"
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="filter-group">
                <label>To date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.end_date}
                  min={this.state.start_date}
                  onChange={this.handleDateChange}
                  name="end_date"
                />
              </div>

              <div className="filter-group">
                <label> Fuel type:</label>
                <FuelList
                  onChange={this.handleProductChange}
                  value={this.state.product_id}
                />
              </div>
              <div className="filter-group">
                <label>Supplier name:</label>
                <VendorList
                  value={this.state.vendor_id}
                  onChange={this.handleVendorChange}
                />
              </div>
              <div className="filter-group with-icon">
                <label>
                  Results to display- {this.state.limit || 20} (Total:{' '}
                  {this.state.count})
                </label>
                <form onSubmit={this.handleQuickSearch}>
                  <input type="text" name="limit" ref={this.limitInput} />
                  <button
                    type="submit"
                    className="search-icon"
                    onClick={this.handleQuickSearch}
                  />
                </form>
              </div>
              <div className="filter-group reset-filter">
                <button onClick={this.resetFilter}>Reset filters</button>
              </div>
              <div className="filter-group">
                  <button
                    className="submit"
                    type="submit"
                    onClick={this.submitForm}
                  >
                    Apply filter
                  </button>
                </div>
            </div>
          </div>
          <div className="table-wrapper">
            {(() => {
              if (this.state.loading) {
                return <DropLoaderLarge />;
              } else {
                return (
                  <TableGrid
                    col={col}
                    row={purchaseList}
                    offset={this.state.offset}
                    count={this.state.count}
                    limit={this.state.limit}
                    handlePagination={this.handlePagination}
                    hideActions={true}
                  
                    exportOptions={{
                      fileName: `Fuel Purchase - ${this.state.start_date} to ${this.state.end_date}.pdf`,
                      reportName: 'Fuel Purchase Report',
                      reportDate: `Date - ${dayjs(this.state.start_date).format(
                        'D MMM YY',
                      )} to ${dayjs(this.state.end_date).format('D MMM YY')}`,
                      reportTotal: `Total VAT -  ${
                        purchaseList
                          .reduce((a, b) => a + (b['vat'] || 0), 0)
                          .toFixed(2) || 0
                      }`,
                      reportQuantity: `Total quantity -  ${
                        purchaseList
                          .reduce((a, b) => a + (b['total_quantity'] || 0), 0)
                          .toFixed(2) || 0
                      }`,
                      reportSales: `Total additional VAT -  ${
                        purchaseList
                          .reduce((a, b) => a + (b['additional_vat'] || 0), 0)
                          .toFixed(2) || 0
                      }`,
                      reportTesting: `Total reverse dealer commission -  ${
                        purchaseList
                          .reduce((a, b) => a + (b['reverse_dealer_commission'] || 0), 0)
                          .toFixed(2) || 0
                      }`,
                      reportBowser: `Total recovery for LFR/SSLF -  ${
                        purchaseList
                          .reduce((a, b) => a + (b['recovery_for_lfr_sslf'] || 0), 0)
                          .toFixed(2) || 0
                      }`,
                      reportCustomerName: '',
                      reportGst: '',
                      reportOpeningBalance: `Total amount -  ${
                        purchaseList
                          .reduce((a, b) => a + (b['base_amount'] || 0), 0)
                          .toFixed(2) || 0
                      }`,
                      reportClosingBalance: `Total discount -  ${
                        purchaseList
                          .reduce((a, b) => a + (b['discount'] || 0), 0)
                          .toFixed(2) || 0
                      }`,
                      reportSaleTotal: `Total sale amount -  ${
                        purchaseList
                          .reduce((a, b) => a + (b['amount'] || 0), 0)
                          .toFixed(2) || 0
                      }`,
                      reportTCSTotal: `Total TCS amount -  ${
                        purchaseList
                          .reduce((a, b) => a + (b['tcs_amount'] || 0), 0)
                          .toFixed(2) || 0
                      }`,
                    }}
                  />
                );
              }
            })()}
            <div className="mini-summary">
              <ul>
              <li>
                  <span>Total  amount: </span>
                  <span>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(
                      purchaseList.reduce(
                        (a, b) => a + (b['base_amount'] || 0),
                        0,
                      ) || 0,
                    )}
                  </span>
                </li>
                <li>
                  <span>Total discount: </span>
                  <span>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(
                      purchaseList.reduce(
                        (a, b) => a + (b['discount'] || 0),
                        0,
                      ) || 0,
                    )}
                  </span>
                </li>
                <li>
                  <span>Total VAT: </span>
                  <span>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(
                      purchaseList.reduce(
                        (a, b) => a + (b['vat'] || 0),
                        0,
                      ) || 0,
                    )}
                  </span>
                </li>
                <li>
                  <span>Total additional VAT: </span>
                  <span>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(
                      purchaseList.reduce(
                        (a, b) => a + (b['additional_vat'] || 0),
                        0,
                      ) || 0,
                    )}
                  </span>
                </li>
                <li>
                  <span>Total reverse dealer commission: </span>
                  <span>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(
                      purchaseList.reduce(
                        (a, b) => a + (b['reverse_dealer_commission'] || 0),
                        0,
                      ) || 0,
                    )}
                  </span>
                </li>
                <li>
                  <span>Total recovery for LFR/SSLF: </span>
                  <span>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(
                      purchaseList.reduce(
                        (a, b) => a + (b['recovery_for_lfr_sslf'] || 0),
                        0,
                      ) || 0,
                    )}
                  </span>
                </li>
                <li>
                  <span>Total TCS amount: </span>
                  <span>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(
                      purchaseList.reduce(
                        (a, b) => a + (b['tcs_amount'] || 0),
                        0,
                      ) || 0,
                    )}
                  </span>
                </li>
                <li>
                  <span>Total sale amount: </span>
                  <span>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(
                      purchaseList.reduce(
                        (a, b) => a + (b['amount'] || 0),
                        0,
                      ) || 0,
                    )}
                  </span>
                </li>
                <li>
                  <span> Total quantity: </span>
                  <span>
                    {new Intl.NumberFormat('en-IN', {}).format(
                      purchaseList.reduce(
                        (a, b) => a + (b['total_quantity'] || 0),
                        0,
                      ) || 0,
                    )}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </main>
      </section>
    );
  }
}

export { FuelPurchaseReport };
