import React, { Component } from 'react';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import services from '@C/Admin/DipCalibration/_utils/data';
import { TableGrid } from '@C/TableGrid';
import { OMCList } from '@C/Utils';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { authHeader } from '@H';

const col = [
  {
    name: 'Chart name',
    key: 'all',
    formatter(props) {
      return (
        <>
          <Link to={`/admin/dip-calibration/chart/${props.uuid}`}>
            {' '}
            {props.name}{' '}
          </Link>
        </>
      );
    },
  },
  {
    name: 'OMC',
    key: 'omc.name',
  },
  {
    name: 'Capacity',
    key: 'capacity',
  },
  {
    name: 'Diameter',
    key: 'diameter',
  },
  {
    name: 'Int.length',
    key: 'internal_length',
  },
  {
    name: 'Notes',
    key: 'notes',
  },
];

export default class DipCalibrationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dipList: [],
      loading: true,
      limit: 20,
      offset: 1,
      count: 0,
      omc_id: '',
      uuid: '',
    };
    this.limitInput = React.createRef();
  }

  componentDidMount() {
    this.getDipCalibrationList();
  }

  getDipCalibrationList = () => {
    services
      .getDipCalibrationList(this.state)
      .then((response) => {
        this.setState({
          dipList: response.data.data.results,
          loading: false,
          count: response.data.data.count,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleOMCChange = (e) => {
    this.setState({ omc_id: e, loading: true }, function () {
      this.getDipCalibrationList();
    });
  };

  confirmDelete = (item) => {
    confirmAlert({
      title: 'Confirm dip calibration  deletion',
      message: 'Are you sure',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteItem(item),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  deleteItem = (item) => {
    const headers = authHeader();
    axios
      .delete(`${process.env.API_URL}/dip-calibration/?uuid=${item.uuid}`, {
        headers,
      })
      .then(() => {
        toast.success('Dip calibration deleted successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        this.getDipCalibrationList();
      });
  };

  resetFilter = () => {
    this.setState(
      {
        limit: 20,
        offset: 1,
        count: 0,
        omc_id: '',
      },
      function () {
        this.getDipCalibrationList();
      },
    );
  };

  handleQuickSearch = (e) => {
    e.preventDefault();
    if (
      this.limitInput.current.value === '' ||
      this.limitInput.current.value === '0'
    ) {
      return;
    }
    this.setState(
      { limit: this.limitInput.current.value, offset: 1 },
      function () {
        this.getDipCalibrationList();
      },
    );
  };

  render() {
    const { dipList } = this.state;
    return (
      <section
        className={`widget full-width list-table ${this.props.cod ? 'inverted' : ''}`}
      >
        <header>
          <h3>Chart List </h3>
        </header>
        <main className="list-content">
          <div className="table-filters">
            <div className="filter-wrapper">
              <div className="filter-group with-icon">
                <label>Results to display (Total: {this.state.count})</label>
                <form onSubmit={this.handleQuickSearch}>
                  <input type="text" name="limit" ref={this.limitInput} />
                  <button
                    type="submit"
                    className="search-icon"
                    onClick={this.handleQuickSearch}
                  />
                </form>
              </div>
              <div className="filter-group">
                <label> OMC</label>
                <OMCList
                  handleOMCChange={this.handleOMCChange}
                  value={this.state.omc_id}
                />
              </div>

              <div className="filter-group reset-filter">
                <button onClick={this.resetFilter}>Reset filters</button>
              </div>
            </div>
          </div>
          <div className="table-wrapper">
            {(() => {
              if (this.state.loading) {
                return <DropLoaderLarge />;
              } else {
                return (
                  <>
                    <TableGrid
                      col={col}
                      row={dipList}
                      offset={this.state.offset}
                      count={this.state.count}
                      limit={this.state.limit}
                      handlePagination={this.handlePagination}
                      onEdit={this.props.onEdit}
                      onDelete={this.confirmDelete}
                    />
                  </>
                );
              }
            })()}
          </div>
        </main>
      </section>
    );
  }
}
