/* eslint-disable simple-import-sort/imports */
import React, { Component } from 'react';
import dayjs from 'dayjs';
import { NavLink } from 'react-router-dom';

import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import { formatCurrency } from '@C/TableGrid';

import services from './data';

class MiniDsr extends Component {
  constructor() {
    super();
    this.state = {
      time_stamp: dayjs().format('YYYY-MM-DD'),
      dsrData: [],
      loading: true,
      events: [],
      month: dayjs().format('MM'),
      year: dayjs().format('YYYY'),
    };
  }

  componentDidMount() {
    this.getDSR();
    this.getDSRDates();
  }

  getDSRDates = () => {
    services
      .getDSRDates(this.state.month, this.state.year)
      .then((response) => {
        const tmp = response.data.data;
        const eventsArr = [];
        tmp.forEach((item) => {
          eventsArr.push({
            date: item.time_stamp,
            display: 'background',
            classNames: item.lock_status ? 'locked' : 'unlocked',
          });
        });
        this.setState({ events: eventsArr }, function () {});
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getDSR = () => {
    services
      .getDSRSummary(this.state.time_stamp)
      .then((response) => {
        this.setState({ dsrData: response.data.data, loading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleDate = (e) => {
    this.setState({ time_stamp: e.target.value, loading: true }, function () {
      this.getDSR();
    });
  };

  handleDateClick = (arg) => {
    this.setState(
      {
        time_stamp: dayjs(arg.event.start).format('YYYY-MM-DD'),
        loading: true,
      },
      function () {
        this.getDSR();
      },
    );
  };

  handleMonthChange = (arg) => {
    const month = arg.view.currentStart.getMonth() + 1;
    const year = arg.view.currentStart.getFullYear();
    this.setState({ month: month, year: year }, function () {
      this.getDSRDates();
    });
  };

  render() {
    const { events, dsrData } = this.state;

    return (
      <>
        <section className="widget full-width dsr-table">
          <header>
            <h3>DSR Summary</h3>
          </header>
          <main className="two-col">
            <div className="col">
              <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin]}
                events={events}
                datesSet={this.handleMonthChange}
                eventClick={this.handleDateClick}
              />
            </div>
            <div className="col">
              {(() => {
                if (this.state.loading) {
                  return <DropLoaderLarge />;
                } else {
                  return (
                    <table className="dsr">
                      <thead>
                        <tr>
                          <th colSpan="2">
                            DSR Summary -{' '}
                            {dayjs(this.state.time_stamp).format('D MMM, YYYY')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Opening Cash Balance</td>
                          <td>{formatCurrency(dsrData.opening_balance)}</td>
                        </tr>
                        <tr>
                          <td>Total Sales </td>
                          <td>{formatCurrency(dsrData.total_sale)}</td>
                        </tr>
                        <tr>
                          <td>Credit Sales</td>
                          <td>{formatCurrency(dsrData.credit_sale)}</td>
                        </tr>
                        <tr>
                          <td> Other Expenses</td>
                          <td>{formatCurrency(dsrData.expenses)}</td>
                        </tr>

                        <tr>
                          <td>Other Incomes</td>
                          <td>{formatCurrency(dsrData.other_income)}</td>
                        </tr>
                        <tr>
                          <td>Fuel Purchases</td>
                          <td>{formatCurrency(dsrData.purchase)}</td>
                        </tr>
                        <tr>
                          <td>Lube Purchases</td>
                          <td>{formatCurrency(dsrData.lube_purchase)}</td>
                        </tr>
                        <tr>
                          <td>Bank Deposit</td>
                          <td>{formatCurrency(dsrData.bank_deposit)}</td>
                        </tr>
                        <tr>
                          <td>Closing Cash Balance</td>
                          <td>{formatCurrency(dsrData.closing_balance)}</td>
                        </tr>

                        <tr>
                          <td> Total fuel sales</td>
                          <td>{formatCurrency(dsrData.fuel_sale)}</td>
                        </tr>
                        <tr>
                          <td> Total lube sales</td>
                          <td>{formatCurrency(dsrData.lube_sale)}</td>
                        </tr>
                        {/* <tr>
                          <td>HSD Sales</td>
                          <td>
                            {formatCurrency(
                              dsrData.products_sale.filter(
                                (item) => item.product == 'HSD',
                              )[0]?.total_sale_value || 0,
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>MS Sales</td>
                          <td>
                            {formatCurrency(
                              dsrData.products_sale.filter(
                                (item) => item.product == 'MS',
                              )[0]?.total_sale_value || 0,
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Other Product Sales</td>
                          <td>
                            {formatCurrency(
                              dsrData.products_sale.filter(
                                (item) => item.product == 'lube',
                              )[0]?.total_sale_value || 0,
                            )}
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                  );
                }
              })()}
              <div className="cta-wrapper">
                <NavLink
                  to={`/account/dsr/${this.state.time_stamp}`}
                  className="submit"
                >
                  {`View DSR - ${dayjs(this.state.time_stamp).format('D MMM, YYYY')}`}
                </NavLink>
              </div>
            </div>
          </main>
        </section>
      </>
    );
  }
}

export { MiniDsr };
