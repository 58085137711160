import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';

import services from '@C/ManageIE/_utils/data';
import { TableGrid } from '@C/TableGrid';
import { PaymentMethodInfo, StaffList } from '@C/Utils';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import IncomeList from '@C/Utils/IncomeList';
import { authHeader } from '@H';

const col = [
  {
    name: 'Date',
    key: 'time_stamp',
    sortable: true,
    type: 'date',
  },
  {
    name: 'Income source',
    key: 'payment_for',
    formatter(props) {
      return <>{props.source}</>;
    },
  },
  {
    name: 'Amount (Excl Tax)',
    key: 'balance',
    type: 'currency',
  },
  {
    name: 'Amount (Inc Tax)',
    key: 'after_tax',
    type: 'currency',
  },
  {
    name: 'Payment mode',
    key: 'payment_method',
    type: 'payment',
    formatter(props) {
      return (
        <>
          <PaymentMethodInfo
            source={props.source}
            method={props.payment_method}
            bank={props.bank}
            wallet={props.other_wallet}
          />
        </>
      );
    },
  },
  {
    name: 'Staff',
    key: 'cashier',
    formatter(props) {
      return (
        <>
          {props.first_name} {props.last_name}
        </>
      );
    },
  },
  {
    name: 'Notes',
    key: 'notes',
  },
];

const enabledActions = [
  {
    action: 'Delete',
    roles: ['Manager', 'Dealer'],
  },
];

class IncomeSalesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      incomeList: [],
      selectedId: '',
      showModal: false,
      staff_id: this.props.cod ? this.props.staff : '',
      cashier_duty_id: this.props.cod ? this.props.duty : '',
      start_date: this.props.cod
        ? this.props.date
        : dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      end_date: this.props.cod ? this.props.date : dayjs().format('YYYY-MM-DD'),
      product_uuid: '',
      loading: true,
      limit: 20,
      offset: 1,
      count: 0,
    };

    this.limitInput = React.createRef();
  }

  componentDidMount() {
    this.getIncomeList();
  }

  getIncomeList = () => {
    services
      .getIncomeList(this.state)
      .then((response) => {
        this.setState({
          incomeList: response.data.data.results,
          loading: false,
          count: response.data.data.count,
        });
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
  };

  handleLimitChange = (e) => {
    this.setState({ limit: e.target.value });
  };

  handlePagination = (e) => {
    this.setState({ offset: e, loading: true }, function () {
      this.getIncomeList();
    });
  };

  handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'start_date' && dayjs(value).isAfter(this.state.end_date)) {
      this.setState({ [name]: value, end_date: value });
    } else if (
      name === 'end_date' &&
      dayjs(value).isBefore(this.state.start_date)
    ) {
      this.setState({ [name]: value, start_date: value });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleStaffChange = (e) => {
    this.setState({ staff_id: e.value });
  };

  handleProductChange = (e) => {
    this.setState({ product_uuid: e.value });
  };

  editItem = (selectedId) => {
    this.setState({ selectedId: selectedId, showModal: true });
  };

  handleQuickSearch = (e) => {
    e.preventDefault();
    if (
      this.limitInput.current.value === '' ||
      this.limitInput.current.value === '0'
    ) {
      return;
    }
    this.setState(
      { limit: this.limitInput.current.value, offset: 1 },
      function () {
        this.getIncomeList();
      },
    );
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };
  editDetails = (item) => {
    this.setState({
      selectedId: item.id,
      showModal: true,
      selectedData: item,
      selectedDate: item.date,
    });
  };

  confirmDelete = (item) => {
    confirmAlert({
      title: 'Confirm income deletion',
      message: 'Are you sure',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteItem(item),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  deleteItem = (item) => {
    const headers = authHeader(1);
    axios
      .delete(
        `${process.env.API_URL}/payment/?transaction_id=${item.uuid}&staff_id=${item.cashier.uuid}&cashier_duty_id=${item.cashier_duty.cashier_duty_id}&source=${item.source}`,
        {
          headers,
        },
      )
      .then(() => {
        toast.success('Transaction deleted successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        this.getIncomeList();
        this.props.onDataChange();
      });
  };

  resetFilter = () => {
    this.setState(
      {
        staff_id: '',
        start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        end_date: dayjs().format('YYYY-MM-DD'),
        product_uuid: '',
        loading: true,
      },
      function () {
        this.getIncomeList();
      },
    );
  };

  submitForm = (e) => {
    e.preventDefault();
    this.setState({ loading: true }, function () {
      this.getIncomeList();
    });
  };

  render() {
    const { incomeList } = this.state;
    return (
      <>
        <section
          className={`widget full-width list-table ${this.props.cod ? 'inverted' : ''}`}
        >
          <header>
            <h3>Income List</h3>
          </header>

          <main>
            {!this.props.cod && (
              <div className="table-filters">
                <div className="filter-wrapper">
                  <div className="filter-group with-icon">
                    <label>
                      Results to display-{this.state.limit || 20} (Total:{' '}
                      {this.state.count})
                    </label>
                    <form onSubmit={this.handleQuickSearch}>
                      <input type="text" name="limit" ref={this.limitInput} />
                      <button
                        type="submit"
                        className="search-icon"
                        onClick={this.handleQuickSearch}
                      />
                    </form>
                  </div>
                  <div className="filter-group">
                    <label>From date:</label>
                    <input
                      className="cstm-date-input"
                      type="date"
                      value={this.state.start_date}
                      name="start_date"
                      onChange={this.handleDateChange}
                    />
                  </div>
                  <div className="filter-group">
                    <label>To date:</label>
                    <input
                      className="cstm-date-input"
                      type="date"
                      value={this.state.end_date}
                      min={this.state.start_date}
                      onChange={this.handleDateChange}
                      name="end_date"
                    />
                  </div>
                  <div className="filter-group">
                    <label>Staff:</label>
                    <StaffList
                      value={this.state.staff_id}
                      onChange={this.handleStaffChange}
                      staffType={['Cashiers', 'Managers', 'Dealer']}
                    />
                  </div>
                  <div className="filter-group">
                    <label>Income Source:</label>
                    <IncomeList
                      onChange={this.handleProductChange}
                      value={this.state.product_uuid}
                      isShowDeleted={true}
                    />
                  </div>

                  <div className="filter-group reset-filter">
                    <button onClick={this.resetFilter}>Reset filters</button>
                  </div>
                  <div className="filter-group">
                    <button
                      className="submit"
                      type="submit"
                      onClick={this.submitForm}
                    >
                      Apply filter
                    </button>
                  </div>
                </div>
              </div>
            )}

            <div className="table-wrapper">
              <div className="mini-summary">
                <ul>
                  <li>
                    <span>Total sale amount: </span>
                    <span>
                      {new Intl.NumberFormat('en-IN', {
                        style: 'currency',
                        currency: 'INR',
                      }).format(
                        incomeList.reduce(
                          (a, b) => a + (b['after_tax'] || 0),
                          0,
                        ) || 0,
                      )}
                    </span>
                  </li>
                </ul>
              </div>
              {(() => {
                if (this.state.loading) {
                  return <DropLoaderLarge />;
                } else {
                  return (
                    <>
                      <TableGrid
                        col={col}
                        row={incomeList}
                        offset={this.state.offset}
                        count={this.state.count}
                        limit={this.state.limit}
                        handlePagination={this.handlePagination}
                        onEdit={this.props.onEdit}
                        onDelete={this.confirmDelete}
                        enabledActions={enabledActions}
                      />
                    </>
                  );
                }
              })()}
            </div>
          </main>
        </section>
      </>
    );
  }
}

export default IncomeSalesList;
