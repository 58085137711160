import React, { Component } from 'react';
StaffList;
import axios from 'axios';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import { Fields, getPaymentModes, Modes } from '@C/Banking/';
import services from '@C/StaffManagement/_utils/data';
import AdvanceSalaryList from '@C/StaffManagement/RouteComponents/AdvanceSalary/AdvanceSalaryList';
import { FilteredStaffList, StaffList } from '@C/Utils';
import { authHeader } from '@H';

class AdvanceSalary extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
    this.myRef = React.createRef();
    this.state = {
      time_stamp: dayjs().format('YYYY-MM-DD'),
      staff_id: '',
      amount: '',
      given_by_id: '',
      notes: '',
      payment: {
        bank_id: null,
        payment_method: 'OFFLINE',
        source: 'CASH',
        check_date: '',
        check_number: '',
        mode: 'cash',
      },
      advanceRepayment: [],
      start_date: '',
      end_date: '',
      limit: 99999,
      offset: 1,
      editMode: false,
      updated: false,
      isSubmitting: false,
      pendingAmount: 0,
    };
  }

  getAdvanceSalaryData = () => {
    services
      .getLatestAdvancePending(this.state)
      .then((res) => {
        this.setState({
          pendingAmount: res.data.data.pending_amount,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleEmployeeChange = (e) => {
    this.setState({ staff_id: e.value }, function () {
      this.getAdvanceSalaryData();
    });
  };

  changeDate = (e) => {
    this.setState({ time_stamp: e.target.value });
  };

  handleReceiverChange = (e) => {
    this.setState({ given_by_id: e.value });
  };

  handlePaymentSelect = (mode, i) => {
    const list = { ...this.state.payment };
    list.mode = mode;
    if (mode == 'cash') {
      list.payment_method = 'OFFLINE';
      list.source = 'CASH';
    }
    this.setState({ payment: list });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onDataChange = (e, i) => {
    const list = { ...this.state.payment };
    list.bank_id = e.bank_id;
    list.check_number = e.bank_reference_id;
    list.check_date = e.check_date;
    list.payment_method = e.payment_method;
    list.source = e.transaction_source;
    this.setState({ payment: list });
  };

  editMode = (item) => {
    const paymentMode = getPaymentModes(
      item.payment.source,
      item.payment.payment_method,
    );

    const paymentList = { ...this.state.payment };
    paymentList.mode = paymentMode;
    paymentList.bank = item.bank;
    paymentList.bank_id = item.bank?.uuid;
    paymentList.bank_reference_id = item.payment.bank_reference_id;
    paymentList.check_date = item.payment.check_date;
    paymentList.payment_method = item.payment.payment_method;
    paymentList.transaction_source = item.payment.source;
    this.setState(
      {
        editMode: true,
        time_stamp: dayjs(item.time_stamp).format('YYYY-MM-DD'),
        staff_id: item.staff.uuid,
        amount: item.salary_advance,
        given_by_id: item.given_by.uuid,
        notes: item.notes,
        id: item.uuid,
        payment: paymentList,
      },
      function () {
        this.getAdvanceSalaryData();
      },
    );
  };
  cancelEdit = () => {
    this.setState({
      time_stamp: dayjs().format('YYYY-MM-DD'),
      staff_id: '',
      amount: '',
      given_by_id: '',
      notes: '',
      payment: {
        bank_id: null,
        payment_method: 'OFFLINE',
        source: 'CASH',
        check_date: '',
        check_number: '',
        mode: 'cash',
      },
      updated: false,
      editMode: false,
      isSubmitting: false,
      pendingAmount: 0,
    });
  };

  submitForm = (e) => {
    e.preventDefault();
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.setState({ isSubmitting: true });
    const url = `${process.env.API_URL}/staff-management/bunk/advance-salary/`;
    let method = 'post';

    const headers = authHeader(1);
    const data = this.state;

    if (this.state.editMode) {
      method = 'put';
      data.uuid = this.state.id;
    }

    axios
      .request({
        url,
        method,
        headers,
        data: data,
      })
      .then(() => {
        toast.success('Transaction updated successfully');
        window.scrollTo({
          behavior: 'smooth',
          top: this.myRef.current.offsetTop - 150,
        });
        this.setState({
          time_stamp: dayjs().format('YYYY-MM-DD'),
          staff_id: '',
          amount: '',
          given_by_id: '',
          notes: '',
          payment: {
            bank_id: null,
            payment_method: 'OFFLINE',
            source: 'CASH',
            check_date: '',
            check_number: '',
            mode: 'cash',
          },
          editMode: false,
          updated: !this.state.updated,
          isSubmitting: false,
          pendingAmount: 0,
        });
        this.validator.hideMessages();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.message);
        this.setState({ isSubmitting: false });
      });
  };

  render() {
    const editMode = this.state.editMode;
    return (
      <>
        <section className="widget full-width" ref={this.myRef}>
          <header>
            <h3> Salary Advance </h3>
          </header>
          <main>
            <form className="inline">
              <div className="input-wrapper with-label ">
                <label> Date</label>
                <input
                  type="date"
                  name="selectDate"
                  onChange={this.changeDate}
                  value={this.state.time_stamp}
                  disabled={editMode}
                />
              </div>
              <div className="input-wrapper with-label ">
                <label>Staff</label>
                {this.validator.message(
                  'staff_id',
                  this.state.staff_id,
                  'required',
                )}
                <StaffList
                  onChange={this.handleEmployeeChange}
                  value={this.state.staff_id}
                  disabled={editMode}
                  staffType={['Cashiers', 'Managers', 'other_staff']}
                />
              </div>
              <div className="input-wrapper with-label ">
                <label>Advance amount </label>
                {this.validator.message(
                  'amount',
                  this.state.amount,
                  'required',
                )}
                <input
                  type="number"
                  onChange={this.handleChange}
                  value={this.state.amount}
                  id="amount"
                  name="amount"
                />
              </div>
              <div className="input-wrapper with-label ">
                <label> Advance pending </label>
                <input
                  type="number"
                  value={this.state.pendingAmount}
                  disabled
                />
              </div>
              <div className="input-wrapper with-label ">
                <label> Advance given by </label>
                {this.validator.message(
                  'given_by_id',
                  this.state.given_by_id,
                  'required',
                )}
                <FilteredStaffList
                  onChange={this.handleReceiverChange}
                  value={this.state.given_by_id}
                  disabled={editMode}
                />
              </div>

              <div className="input-wrapper with-label ">
                <label> Notes </label>
                <input
                  type="text"
                  value={this.state.notes}
                  name="notes"
                  onChange={this.handleChange}
                />
              </div>
              <div className="input-group">
                <div className="input-wrapper with-label">
                  <label>Payment mode</label>
                  <Modes
                    handleModeChange={this.handlePaymentSelect}
                    value={this.state.payment.mode || 'cash'}
                    disabled={editMode}
                  />
                </div>
                <Fields
                  method={this.state.payment.mode}
                  onDataChange={this.onDataChange}
                  bank={this.state.payment.bank}
                  bank_reference_id={this.state.payment.bank_reference_id}
                  check_date={this.state.payment.check_date}
                  disabled={editMode}
                />
              </div>
              <div className="form-actions">
                <button
                  className="submit"
                  onClick={this.submitForm}
                  disabled={this.state.isSubmitting}
                >
                  Submit
                </button>
                {editMode && (
                  <button
                    className="red-btn"
                    disabled={this.state.isSubmitting}
                    onClick={this.cancelEdit}
                  >
                    Cancel
                  </button>
                )}
              </div>
            </form>
          </main>
        </section>
        <AdvanceSalaryList onEdit={this.editMode} key={this.state.updated} />
      </>
    );
  }
}
export { AdvanceSalary };
