import React, { Component } from 'react';
import dayjs from 'dayjs';

import services from '@C/CreditCustomerProfile/_utils/data';
import { ProductList, VehicleList } from '@C/CreditCustomers/_utils/';
import { TableGrid } from '@C/TableGrid';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { authService } from '@S';

const col = [
  {
    name: 'Date',
    key: 'time_stamp',
    sortable: true,
    type: 'date',
  },
  {
    name: 'Slip',
    key: 'credit_slip',
    formatter(props) {
      return <>{props.slip_number}</>;
    },
  },
  {
    name: 'Staff',
    key: 'cashier',
    formatter(props) {
      return (
        <>
          {props.first_name} {props.last_name}
        </>
      );
    },
  },
  {
    name: 'Customer',
    key: 'customer',
    formatter(props) {
      return (
        <>
          {props.first_name} {props.last_name}
        </>
      );
    },
  },
  {
    name: 'Vehicle',
    key: 'credit_slip',
    formatter(props) {
      return <>{props.vehicle_number || '----'}</>;
    },
  },
  {
    name: 'Product',
    key: 'credit_slip',
    formatter(props) {
      return <>{props.product.product}</>;
    },
  },
  {
    name: 'Rate',
    key: 'credit_slip',
    formatter(props) {
      return <>{props.product.price}</>;
    },
  },
  {
    name: 'Qty',
    key: 'quantity',
  },
  {
    name: 'Amount',
    key: 'amount',
    type: 'currency',
  },
  {
    name: 'Amt received',
    key: 'amount_received',
    type: 'currency',
  },
  {
    name: 'Balance',
    key: 'balance',
    type: 'currency',
  },
];

class CreditLedger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slipList: [],
      selectedId: '',
      showModal: false,
      staff_id: '',
      start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      end_date: dayjs().format('YYYY-MM-DD'),
      product_id: '',
      customer_id: authService.currentUserId,
      slip_no: '',
      vehicle_number: '',
      loading: false,
      productList: this.props.products,
      customerList: this.props.customers,
      limit: 20,
      offset: 1,
      count: 0,
    };
    this.limitInput = React.createRef();
  }

  componentDidMount() {
    this.getCreditLedger();
  }

  handleQuickSearch = (e) => {
    e.preventDefault();
    if (
      this.limitInput.current.value === '' ||
      this.limitInput.current.value === '0'
    ) {
      return;
    }
    this.setState(
      { limit: this.limitInput.current.value, offset: 1 },
      function () {
        this.getCreditLedger();
      },
    );
  };

  getCreditLedger = () => {
    services
      .getCreditLedger(this.state)
      .then((response) => {
        this.setState({
          slipList: response.data.data.results,
          count: response.data.data.count,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'start_date' && dayjs(value).isAfter(this.state.end_date)) {
      this.setState({ [name]: value, end_date: value, loading: true }, () =>
        this.getCreditLedger(),
      );
    } else if (
      name === 'end_date' &&
      dayjs(value).isBefore(this.state.start_date)
    ) {
      this.setState({ [name]: value, start_date: value, loading: true }, () =>
        this.getCreditLedger(),
      );
    } else {
      this.setState({ [name]: value, loading: true }, () =>
        this.getCreditLedger(),
      );
    }
  };

  handlePagination = (e) => {
    this.setState({ offset: e }, function () {
      this.getCreditLedger();
    });
  };

  handleStaffChange = (e) => {
    this.setState({ staff_id: e.value, loading: true }, function () {
      this.getCreditLedger();
    });
  };

  handleVehicleChange = (e) => {
    this.setState(
      { vehicle_number: e?.value || '', loading: true },
      function () {
        this.getCreditLedger();
      },
    );
  };

  handleBillChange = (e) => {
    this.setState({ slip_no: e.target.value });
  };

  handleProductChange = (e) => {
    this.setState({ product_id: e?.value || '', loading: true }, function () {
      this.getCreditLedger();
    });
  };

  handleCategoryChange = (e) => {
    this.setState({ customer_id: e, loading: true }, function () {
      this.getCreditLedger();
    });
  };

  resetFilter = () => {
    this.limitInput.current.value = 20;
    this.setState(
      {
        staff_id: '',
        start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        end_date: dayjs().format('YYYY-MM-DD'),
        product_id: '',
        loading: true,
        slip_number: '',
        vehicle_number: '',
        limit: 20,
        offset: 1,
      },
      function () {
        this.getCreditLedger();
      },
    );
  };

  render() {
    const { slipList } = this.state;

    return (
      <section className="widget full-width list-table inverted">
        <header>
          <h3>Credit Ledger</h3>
        </header>
        <main>
          <div className="table-filters">
            <div className="filter-wrapper">
              <div className="filter-group">
                <label>From date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.start_date}
                  name="start_date"
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="filter-group">
                <label>To date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.end_date}
                  min={this.state.start_date}
                  onChange={this.handleDateChange}
                  name="end_date"
                />
              </div>
              <div className="filter-group">
                <label>Product:</label>
                <ProductList
                  onProductChange={this.handleProductChange}
                  value={this.state.product_id}
                  options={this.state.productList}
                />
              </div>
              <div className="filter-group">
                <label>Vehicle:</label>
                <VehicleList
                  value={this.state.vehicle_number}
                  handleDropdownChange={this.handleVehicleChange}
                  selectedCustomerId={this.state.customer_id}
                />
              </div>
              <div className="filter-group with-icon">
                <label>
                  Results to display- {this.state.limit || 20} (Total:{' '}
                  {this.state.count})
                </label>
                <form onSubmit={this.handleQuickSearch}>
                  <input type="text" name="limit" ref={this.limitInput} />
                  <button
                    type="submit"
                    className="search-icon"
                    onClick={this.handleQuickSearch}
                  />
                </form>
              </div>
              <div className="filter-group reset-filter">
                <button onClick={this.resetFilter}>Reset filters</button>
              </div>
            </div>
          </div>
          <div className="table-wrapper">
            <div className="mini-summary">
              <ul>
                <li>
                  <span> Total outstanding amount: </span>
                  <span>
                    {new Intl.NumberFormat('en-IN', {}).format(
                      parseFloat(slipList[0]?.balance).toFixed(2) || 0,
                    )}
                  </span>
                </li>
              </ul>
            </div>
            {(() => {
              if (this.state.loading) {
                return <DropLoaderLarge />;
              } else {
                return (
                  <TableGrid
                    col={col}
                    row={slipList}
                    offset={this.state.offset}
                    count={this.state.count}
                    limit={this.state.limit}
                    handlePagination={this.handlePagination}
                    hideActions={true}
                    exportOptions={{
                      fileName: `Credit Ledger - ${this.state.start_date} to ${this.state.end_date}.pdf`,
                      reportName: 'Credit Ledger Report',
                      reportDate: `Date - ${dayjs(this.state.start_date).format(
                        'D MMM YY',
                      )} to ${dayjs(this.state.end_date).format('D MMM YY')}`,
                      reportTotal: `Total outstanding amount -  ${parseFloat(slipList[0]?.balance).toFixed(2) || 0
                        }`,
                      reportQuantity: '',
                      reportSales: '',
                      reportTesting: '',
                      reportBowser: '',
                      reportCustomerName: '',
                      reportGst: '',
                      reportOpeningBalance: '',
                      reportClosingBalance: '',
                    }}
                  />
                );
              }
            })()}
          </div>
        </main>
      </section>
    );
  }
}

export { CreditLedger };
