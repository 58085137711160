import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { ShiftsList, StaffList } from '@C/Utils';
import { CustomerList, ProductList, VehicleList } from '@C/CreditCustomers/_utils/';
import services from '@C/CreditCustomers/_utils/data';
import { TableGrid } from '@C/TableGrid';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { authHeader } from '@H';


const col = [
  {
    name: 'Date',
    key: 'time_stamp',
    sortable: true,
    type: 'date',
  },
  {
    name: 'Slip #',
    key: 'credit_slip',
    formatter(props) {
      return <>{props.slip_number}</>;
    },
  },
  {
    name: 'Staff',
    key: 'cashier',
    formatter(props) {
      return (
        <>
          {props.first_name} {props.last_name}
        </>
      );
    },
  },
  {
    name: 'Customer',
    key: 'customer',
    formatter(props) {
      return (
        <>
          {props.first_name} {props.last_name}
        </>
      );
    },
  },
  {
    name: 'Vehicle',
    key: 'credit_slip',
    formatter(props) {
      return <>{props.vehicle_number || '---'}</>;
    },
  },
  {
    name: 'Product',
    key: 'credit_slip',
    formatter(props) {
      return <>{props.product.product}</>;
    },
  },
  {
    name: 'Rate',
    key: 'credit_slip',
    type: 'currency',
    formatter(props) {
      return <>{props.product.price}</>;
    },
  },
  {
    name: 'Qty',
    key: 'quantity',
  },
  {
    name: 'Amount',
    key: 'amount',
    type: 'currency',
  },
  {
    name: 'Amount received',
    key: 'amount_received',
    type: 'currency',
  },
  {
    name: 'Balance',
    key: 'balance',
    type: 'currency',
  },
];

class CustomerLedger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slipList: [],
      balanceList: [],
      selectedId: '',
      showModal: false,
      staff_id: '',
      start_date: dayjs()
        .subtract(1, 'month')
        .format('YYYY-MM-DD'),
      end_date: dayjs().format('YYYY-MM-DD'),
      product_id: '',
      customer_id: '',
      shift_id: '',
      slip_no: '',
      vehicle_number: '',
      loading: false,
      productList: this.props.products,
      customerList: this.props.customers,
      limit: 20,
      offset: 1,
      count: 0,
    };

    this.limitInput = React.createRef();
  }

  componentDidMount() {
    this.getCreditLedger();
  }

  getCreditLedger = () => {
    services
      .getCreditLedger(this.state)
      .then((response) => {
        this.setState({
          slipList: response.data.data.results,
          balanceList: response.data.data.balances,
          count: response.data.data.count,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'start_date' && dayjs(value).isAfter(this.state.end_date)) {
      this.setState(
        { [name]: value, end_date: value }
      );
    } else if (name === 'end_date' && dayjs(value).isBefore(this.state.start_date)) {
      this.setState(
        { [name]: value, start_date: value }
      );
    } else {
      this.setState(
        { [name]: value });
    }
  };

  handleShiftChange = (e) => {
    this.setState({ shift_id: e.value });
  };

  handleStaffChange = (e) => {
    this.setState({ staff_id: e.value });
  };

  handleBillChange = (e) => {
    this.setState({ slip_no: e.target.value });
  };

  handleProductChange = (e) => {
    this.setState({ product_id: e?.value || '' });
  };

  handleCustomerChange = (e) => {
    this.setState({ customer_id: e.value });
  };

  handleVehicleChange = (e) => {
    this.setState({ vehicle_number: e?.value || '' });
  };

  handleCategoryChange = (e) => {
    this.setState({ customer_id: e });
  };
  handlePagination = (e) => {
    this.setState({ offset: e, loading: true }, function () {
      this.getCreditLedger();
    });
  };
  handleQuickSearch = (e) => {
    e.preventDefault();
    if (this.limitInput.current.value === '' || this.limitInput.current.value === '0') {
      return;
    }
    this.setState(
      { limit: this.limitInput.current.value, offset: 1 },
      function () {
        this.getCreditLedger();
      },
    );
  };
  editItem = (selectedId) => {
    this.setState({ selectedId: selectedId, showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  resetFilter = () => {
    this.setState(
      {
        staff_id: '',
        start_date: dayjs()
          .subtract(1, 'month')
          .format('YYYY-MM-DD'),
        end_date: dayjs().format('YYYY-MM-DD'),
        product_id: '',
        customer_id: '',
        loading: true,
        slip_number: '',
        vehicle_number: '',
      },
      function () {
        this.getCreditLedger();
      },
    );
  };

  confirmDelete = (id) => {
    confirmAlert({
      title: 'Confirm sale deletion',
      message: 'Are you sure',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteItem(id),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  deleteItem = (id) => {
    const headers = authHeader(1);
    axios
      .delete(`${process.env.API_URL}/payment/?transaction_id=${id}`, {
        headers,
      })
      .then((response) => {
        toast.success('Sale deleted successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        this.getCreditLedger();
      });
  };

  submitForm = (e) => {
    e.preventDefault();
    this.setState({ loading: true }, function () {
      this.getCreditLedger();
    })
  }


  render() {
    const { slipList, balanceList } = this.state;

    return (
      <section className="widget full-width list-table">
        <header>
          <h3>Customer Ledger</h3>
        </header>
        <main>
          <div className="table-filters">
            <div className="filter-wrapper">
              <div className="filter-group with-icon">
                <label>Results to display - (Total: {this.state.count})</label>
                <form onSubmit={this.handleQuickSearch}>
                  <input type="text" name="limit" ref={this.limitInput} />
                  <button
                    type="submit"
                    className="search-icon"
                    onClick={this.handleQuickSearch}
                  />
                </form>
              </div>
              <div className="filter-group">
                <label>From date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.start_date}
                  name="start_date"
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="filter-group">
                <label>To date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.end_date}
                  min={this.state.start_date}
                  onChange={this.handleDateChange}
                  name="end_date"
                />
              </div>
              <div className="filter-group">
                <label>Staff:</label>
                <StaffList
                  value={this.state.staff_id}
                  onChange={this.handleStaffChange}
                  staffType={['Cashiers', 'Managers', 'Dealer']}
                />
              </div>
              <div className="filter-group">
                <label>Shift:</label>
                <ShiftsList
                  value={this.state.shift_id}
                  onChange={this.handleShiftChange} />
              </div>
              <div className="filter-group">
                <label>Product:</label>
                <ProductList
                  onProductChange={this.handleProductChange}
                  value={this.state.product_id}
                  options={this.state.productList}
                />
              </div>
              <div className="filter-group">
                <label>Customer:</label>
                <CustomerList
                  onCustomerChange={this.handleCustomerChange}
                  value={this.state.customer_id}
                  options={this.state.customerList}
                />
              </div>
              <div className="filter-group">
                <label>Vehicle:</label>
                <VehicleList
                  value={this.state.vehicle_number}
                  handleDropdownChange={this.handleVehicleChange}
                  selectedCustomerId={this.state.customer_id}
                />
              </div>
              <div className="filter-group reset-filter">
                <button onClick={this.resetFilter}>Reset filters</button>
              </div>
              <div className="filter-group">
                  <button
                    className="submit"
                    type="submit"
                    onClick={this.submitForm}
                  >
                    Apply filter
                  </button>
                </div>
            </div>
          </div>
          <div className="table-wrapper">
            {this.state.customer_id && (
              <div className="mini-summary">
                <ul>
                  {/* <li>
                    <span> Total outstanding amount: </span>
                    <span>
                      {new Intl.NumberFormat('en-IN', {
                      }).format(
                        parseFloat(slipList[0]?.balance).toFixed(2) ||
                        0,
                      )}
                    </span>
                  </li> */}
                  <li>
                    <span> Total Credit sale amount: </span>
                    <span>{new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(
                      slipList.reduce((a, b) => a + (b['amount'] || 0), 0) || 0
                    )}
                    </span>
                  </li>
                  <li>
                    <span> Total amount received: </span>
                    <span>{new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(
                      slipList.reduce((a, b) => a + (b['amount_received'] || 0), 0) || 0
                    )}
                    </span>
                  </li>
                </ul>
              </div>
            )}
            <div className="mini-summary">
              {balanceList.map((item, i) => (
                <ul>
                  <li>
                    <span> {item.opening_date} : </span>
                    <span>
                      {new Intl.NumberFormat('en-IN', {
                        style: 'currency',
                        currency: 'INR',
                      }).format(item.opening_balance_on_date.toFixed(2) || 0)}
                    </span>
                  </li>
                  <li>
                    <span> {item.closing_date} : </span>
                    <span>
                      {new Intl.NumberFormat('en-IN', {
                        style: 'currency',
                        currency: 'INR',
                      }).format(item.closing_balance_on_date.toFixed(2) || 0)}
                    </span>
                  </li>
                </ul>
              )
              )
              }
            </div>
            {(() => {
              if (this.state.loading) {
                return <DropLoaderLarge />;
              } else {
                return (
                  <>
                    <TableGrid
                      col={col}
                      row={slipList}
                      offset={this.state.offset}
                      count={this.state.count}
                      limit={this.state.limit}
                      handlePagination={this.handlePagination}
                      hideActions={true}
                      exportOptions={{
                        fileName: `Credit Ledger - ${this.state.start_date} to ${this.state.end_date
                          }.pdf`,
                        reportName: 'Credit Ledger Report',
                        reportDate: `Date - ${dayjs(this.state.start_date).format(
                          'D MMM YY',
                        )} to ${dayjs(this.state.end_date).format('D MMM YY')}`,
                        reportCustomerName: `${slipList[0]?.customer?.first_name} ${slipList[0]?.customer?.last_name}`,
                        // reportTotal: `Total outstanding amount -  ${parseFloat(slipList[0]?.balance).toFixed(2) ||
                        //   0}`,
                        reportTotal: '',
                        reportQuantity: `Total Credit sale amount -  ${slipList
                          .reduce((a, b) => a + (b['amount'] || 0), 0)
                          .toFixed(2) || 0
                          }`,
                        reportSales: `Total amount received -  ${slipList
                          .reduce((a, b) => a + (b['amount_received'] || 0), 0)
                          .toFixed(2) || 0
                          }`,
                        reportTesting: '',
                        reportSaleTotal: '',
                        reportTCSTotal: '',
                        reportBowser: '',
                        reportGst: `GST - ${slipList[0]?.customer?.customer_gst}`,
                        reportOpeningBalance: `${balanceList[0]?.opening_date} : ${balanceList[0]?.opening_balance_on_date.toFixed(2)}`,
                        reportClosingBalance: `${balanceList[0]?.closing_date} : ${balanceList[0]?.closing_balance_on_date.toFixed(2)}`,
                      }}
                    />
                  </>
                );
              }
            })()}
          </div>
        </main>
      </section>
    );
  }
}

export { CustomerLedger };
