import axios from 'axios';

import { authHeader } from '@H';

export default {
  getDealersList: () =>
    axios({
      method: 'GET',
      headers: authHeader(),
      url: `${process.env.API_URL}/users/dealers-list/`,
    }),
  getDipCalibrationList: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(),
      url: `${process.env.API_URL}/dip-calibration/?omc_id=${data.omc_id}&limit=${data.limit}&offset=${data.offset}`,
    }),
};
